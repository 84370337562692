<template>
  <div class="job-category-about-work">
    <div class="wrap">
      <div class="title-cover">
        <div class="title">
          <UiHeading
            :h="'2'"
            :type="'A2'"
            :text="'Job Details'"
            :subTitle="subTitle"
          />
          <img
            src="@/assets/job-category/ezgif.com-gif-maker-G.png"
            alt="説明イラスト"
            class="denkyu"
          />
        </div>
      </div>
      <p class="sub-title">仕事内容</p>
      <div class="work-detail">
        <p v-html="detail"></p>
        <div class="img-cover">
          <img
            src="@/assets/job-category/GH-kyujin-work-illustration001.webp"
            alt="お仕事イメージ1"
            v-show="topImg === true"
          />
          <img
            :src="require(`@/assets/job-category/${imgPath1}`)"
            alt="お仕事イメージ2"
          />
        </div>
      </div>
      <p class="sub-title">必要となる対応・能力・スキル</p>
      <div class="work-detail">
        <div class="img-cover">
          <img
            :src="require(`@/assets/job-category/${imgPath2}`)"
            alt="お仕事イメージ3"
            class="pc-only"
          />
          <img
            src="@/assets/GH-kyujin-top-illustration004.webp"
            alt="お仕事イメージ"
            class="pc-only"
            v-show="middleImg === true"
          />
        </div>
        <p v-html="skill"></p>
      </div>

      <p class="sub-title">身につくスキルや経験</p>
      <div class="work-detail">
        <p v-html="experience"></p>
        <div class="img-cover2">
          <img
            src="@/assets/job-category/GH-kyujin-work-illustration002.webp"
            alt="お仕事イメージ"
            class="bottom-img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "../Ui/Heading.vue";
export default {
  name: "JobCategoryAboutWork",
  components: { UiHeading },
  props: {
    path: String,
    detail: String,
    skill: String,
    experience: String,
    subTitle: String,
    imgPath1: String,
    imgPath2: String,
    topImg:Boolean,
    middleImg:Boolean,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.job-category-about-work {
  background-color: var(--main);
  padding-top: 200px;
  padding-bottom: 100px;
  @media screen and(max-width: 767px) {
    padding-top: 100px;
  }
  .title-cover {
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      display: flex;
      align-items: center;
      padding-left: 50px;
      @media screen and(max-width: 767px) {
        flex-direction: column-reverse;
        padding-left: 0;
      }
      .denkyu {
        width: 100px;
        margin-left: 20px;
        margin-bottom: 40px;
      }
    }
  }
  .sub-title {
    background-color: var(--sub);
    color: white;
    text-align: center;
    padding: 10px 0;
    font-size: 22px;
    margin-top: 30px;
  }
  .work-detail {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 80px;
    @media screen and (max-width: 767px) {
      display: block;
    }
    p {
      color: white;
      width: 75%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
    .img-cover,
    .img-cover2 {
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      @media screen and (max-width: 767px) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 30px;
        img {
          width: 40%;
        }
        .bottom-img {
          @media screen and(max-width: 767px) {
            margin-left: 60%;
          }
        }
      }
    }
    .img-cover {
      justify-content: space-around;
    }
    .img-cover2 {
      justify-content: flex-end;
    }
  }
}
/deep/ul{
  list-style: disc;
  li{
    margin-left: 1em;
  }
}
/deep/span{
  font-weight: bold;
  font-size: 22px;
  color: var(--sub);
}
</style>
