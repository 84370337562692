<template>
  <div class="sample-qa-qestion">

    <div>
      <div class="outer">
        <div class="bar-box">
            <div class="bar-no">
              <p>Q</p>
            </div>
          <div class="cover">       
              <p v-html="qestion" class="bar-text"></p>
          <svg
            id="レイヤー_1"
            data-name="レイヤー 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 82 82"
            class="open"
            v-if="openContent === false"
            width="50px"
            height="50px"
          >
            <defs></defs>
            <path
              class="cls-1"
              d="M764,510v82h82V510Zm41,56.17-18.19-22.33,3.1-2.53L805,559.83l15.09-18.52,3.1,2.53Z"
              transform="translate(-764 -510)"
            />
          </svg>

          <svg
            version="1.1"
            id="レイヤー_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 82 82"
            style="enable-background: new 0 0 82 82"
            xml:space="preserve"
            class="open"
            v-else-if="openContent === true"
            width="50px"
            height="50px"
          >
            <path
              class="st0"
              d="M82,82V0L0,0l0,82H82z M41,25.8l18.2,22.3l-3.1,2.5L41,32.2L25.9,50.7l-3.1-2.5L41,25.8z"
            />
          </svg>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SampleQaQestion",
  components: {},
  props: {
    qestion: String,

    openContent: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.cls-1,
.st0 {
  fill: var(--sub);
}
.outer {
  margin: 5px 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  @media screen and (max-width: 767px) {
    margin: 15px 0;
  }

  .bar-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100px;
    width: 100%;
    padding: 0 50px;
    border: 1px solid gray;
    @media screen and (max-width: 767px) {
      align-items: flex-start;
      height:auto;
      min-height: auto;
      padding:10px;
    }
    // @media screen and (max-width: 709px) {
    //   flex-direction: column;
    //   padding: 0 10px; 
    // }
    .cover {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      width: 95%;
      @media screen and (max-width: 767px){
              align-items: flex-start;
              height: auto;
      }
      // @media screen and (max-width: 709px) {
      //   height: auto;
      //   align-items: flex-start;
      //   width: 100%;
      // }
    }
    .bar-no {
      min-width: 75px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-right: 1px solid gray;
      @media screen and (max-width: 767px){
        align-items: flex-start;
        border-right: none;
        min-width: 20px;
      }

      p {
        font-size: 27px;
        color: var(--sub);
        @media screen and (max-width: 767px){
          // margin-top: 5px;
        }
      }
    }
    .bar-text {
      font-size: 22px;
      padding-left: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      width: 88%;
      color: var(--main);
      // font-family: var(--fontfamilySub);
      //2行になりそうなタイトルにつける
      ::v-deep span {
        font-size: 24px;
      }
      @media screen and (max-width: 767px) {
        font-size: 16px;
        padding-left: 10px;
      }
      // @media screen and (max-width: 709px) {
      //   padding: 0;
      //   display: flex;
      //   justify-content: left;
      //   min-height: 100px;
      //   display: flex;
      //   align-items: flex-start;
      // }
    }
  }
}

.open {
  width: 50px;
  height: 50px;
    @media screen and (max-width: 709px) {
      width: 38px;
      height: 38px;
      margin-left: 5px;
  //     margin-left: calc(100% - 30px);
  //     margin-bottom: 10px;
    }
}
.open:hover {
  filter: opacity(85%);
  cursor: pointer;
}
.v-application p {
  font-family: "游明朝"!important;
}
</style>