<template>
  <div class="top">
    <div class="cover">
      <div class="filter">
        <video ref="player" autoplay muted playsinline loop>
          <source src="video/header-mono.mp4" />
          <!-- <source src="@/assets/header1280-720px.mp4" /> -->
          このブラウザではサポートされていません。
        </video>
      </div>
      <div class="text">
        <h1>株式会社グレイトヘルプ<br class="tb" />求人サイト</h1>
        <p class="bosyu">
          プログラマー・ウェブサイト制作<span class="pc-only">・</span
          ><br class="tb" />デザイナー・事務の求人
        </p>
        <div class="img-cover">
          <img
            src="@/assets/GH-kyujin-top-h-pa-1.webp"
            alt="勤務時間 勤務曜日が自由"
            class="pc-only anim01"
          />
          <img
            src="@/assets/GH-kyujin-top-h-pa-2.webp"
            alt="1分単位で給与を計上"
            class="pc-only anim02"
          />
          <img
            src="@/assets/GH-kyujin-top-h-pa-3.webp"
            alt="昼食の休憩なしでの勤務OK"
            class="pc-only anim03"
          />
          <img
            src="@/assets/GH-kyujin-top-h-pa-1sp.webp"
            alt="勤務時間 勤務曜日が自由"
            class="sp-only anim01-sp"
          />
          <img
            src="@/assets/GH-kyujin-top-h-pa-2sp.webp"
            alt="1分単位で給与を計上"
            class="sp-only anim02-sp"
          />
          <img
            src="@/assets/GH-kyujin-top-h-pa-3sp.webp"
            alt="昼食の休憩なしでの勤務OK"
            class="sp-only anim03-sp"
          />
        </div>
        <p class="catch">勤務時間が自由。<br class="tb">働きやすさを求める人に。</p>
      </div>
      <!-- <div class="container" :class="{
        'animation-start':
          (path !== '/' && scrollY >= 0) || (path === '/' && scrollY >= 800),
      }" style="font-family: '游明朝','游明朝体'"> -->
      <!-- <div ref="container" class="container animation-start">
        <p class="catch">勤務時間が自由。<br>働きやすさを求める人に。</p>
        <h1>
          株式会社グレイトヘルプ<br class="sp-only" />
          求人サイト
        </h1>
        <p>
          プログラマー<span class="pc-only">・</span><br class="sp-only" />ウェブサイト制作<span class="pc-only">・</span><br
            class="sp-only"
          />デザイナー<span class="pc-only">・</span><br class="sp-only" />事務の求人
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "top",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  mounted: function () {
    let player = this.$refs.player;
    let container = this.$refs.container;

    player.addEventListener("timeupdate", function () {
      if (player.currentTime > 43) {
        container.style.display = "none";
        container.classList.remove("animation-start");
      }
    });

    player.addEventListener("ended", function () {
      console.log("ended");
      container.style.display = "";
      container.classList.add("animation-start");
      player.play();
    });
  },
};
</script>

<style lang="scss" scoped>
.cover {
  background-color: rgb(183, 190, 216); //動画に黒のフィルターかける
  // background-color: red; //動画に黒のフィルターかける
  display: inline-block; //上記とセット
  //ここからスマホ全画面用
  margin: 0 auto;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: -70px;
  position: relative;

  .filter {
    opacity: 0.8;
    display: block;
    background-image: url(~@/assets/black.webp);

    //ここからスマホ全画面用
    position: relative !important;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    background: no-repeat center center/cover;
  }

  video {
    min-width: 100%;
    min-height: 100vh;
    position: absolute;
  }

  /*動画よりも画面が縦に長くなるとき用*/
  @media (max-aspect-ratio: 16/9) {
    video {
      height: 100%;
      left: 60%;
      transform: translateX(-60%);
    }
  }

  .animation-start {
    animation: fadeout-anim2 40s linear forwards;
  }

  .text {
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    @media screen and (min-width: 768px) and (max-width: 1150px){
      width: 70%;
    }
    @media screen and (max-width: 767px){
      width: 80%;
      padding-top: 80px;
    }
    h1 {
      font-size: 47px;
      color: var(--main);
      white-space: nowrap;
      line-height: 1.4em;
        @media screen and (min-width: 768px) and (max-width: 1150px) {
          line-height: 1.4em;
          }
      @media screen and (max-width: 767px){
        line-height: 1.4em;
        font-size: 27px;
      }

    }
    .bosyu {
      color: var(--main);
      padding: 50px 0;
      font-size: 20px;
      @media screen and (max-width: 767px){
        font-size: 17px;
      }
    }
    .catch {
      color: var(--main);
      font-size: 30px;
      padding-top: 50px;
      @media screen and (max-width: 767px){
        font-size: 17px;
      }
    }
    .img-cover {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
      img {
        width: 30%;
        @media screen and (max-width: 767px){
          width: 100%;
          margin-bottom: 10px;
        }
      }
      .anim01 {
        animation: katakata 6s infinite ease-in-out alternate;
        animation-delay: 5s;
      }
      .anim02 {
        animation: katakata 6s infinite ease-in-out alternate;
        animation-delay: 1s;
      }
      .anim03 {
        animation: katakata 6s infinite ease-in-out alternate;
        animation-delay: 8s;
      }
      .anim01-sp {
        animation: purupuru_anim 6s infinite ease-in-out alternate;
        animation-delay: 5s;
      }
      .anim02-sp {
        animation: purupuru_anim 6s infinite ease-in-out alternate;
        animation-delay: 1s;
      }
      .anim03-sp {
        animation: purupuru_anim 6s infinite ease-in-out alternate;
        animation-delay: 8s;
      }
    }
  }
  @keyframes katakata {
    0% {
      transform: translate(0, 0) rotate(0deg);
    }
    21% {
      transform: translate(0, 0) rotate(0deg);
    }

    22% {
      transform: translate(0, 0) rotate(-3deg);
    }
    23% {
      transform: translate(0, -1px) rotate(0deg);
    }
    24% {
      transform: translate(0, -1px) rotate(3deg);
    }

    25% {
      transform: translate(1px, 0) rotate(-2deg);
    }
    26% {
      transform: translate(0, 1px) rotate(0deg);
    }
    27% {
      transform: translate(0, 0) rotate(3deg);
    }

    28% {
      transform: translate(0, 0) rotate(0deg);
    }
    100% {
      transform: translate(0, 0) rotate(0deg);
    }
  }
  @keyframes purupuru_anim {
  0% {  transform: translate(0, 0);}
  3% {  transform: translate(-5px, -0);}
  6% { transform: translate(5px, 0);}
  9% { transform: translate(-5px, -0);}
  12% { transform: translate(5px, 0);}
  15% { transform: translate(-5px, -0);}
  18% { transform: translate(0, 0);}
  100% {  transform: translate(0, 0);}
}
.tb{
  display: none;
      @media screen and (max-width: 1500px){
    display: initial;
  }
}
}
</style>
