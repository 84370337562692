<template>
  <div class="home">

    <div class="top">
      <HomeTop />
    </div>
    <div class="about-us wrap space-top-70 space-top-130">
      <div class="left">
        <p class="blue">Recruit</p>
        <h1 class="title">
          株式会社グレイトヘルプの<br class="sp-only" />求人・採用について
        </h1>
      </div>
      <div class="right">
        <p>
          仕事において「時間的な自由を求める人」は、ぜひ株式会社グレイトヘルプの求人をチェックしてみてください。<br>家事や子育てに忙しい主婦・主夫、フリーランス・個人事業主としてご自身のお仕事をしながら勤務される方が主となって活躍する職場です。<br>出勤曜日が自由、勤務時間が自由、無駄な拘束時間なしなど、仕事において時間的な制約がある人が働きやすい勤務条件と職場環境を整えています。
        </p>
      </div>
      <img
        src="@/assets/ezgif.com-gif-maker-A.png"
        alt="説明イラスト"
        class="illust-a"
      />
      <img
        src="@/assets/ezgif.com-gif-maker-B.png"
        alt="説明イラスト"
        class="illust-b"
      />
    </div>

    <div class="recommendation">
      <HomeRecommendation />
    </div>

    <div class="space-top-70 space-top-130">
      <HomeWorkPlace />
    </div>

    <div class="space-top-130">
      <HomeWorkEnvironment />
    </div>

    <div class="space-top-130">
      <HomeWorkEnvironment2 />
    </div>

    <div class="space-top-130 space-top-70 pala-top">
      <HomeMessage />
    </div>

    <div class="space-top-130 space-top-70 pala-top">
      <HomeOurBuisiness />
    </div>

    <div class="space-top-130 space-top-70">
      <HomeOneDay />
    </div>

    <div class="space-top-130 space-top-70 pala-top">
      <HomeOurBuisiness2 />
    </div>

    <div class="space-top-130 space-top-70">
      <HomeOpenPositions id="open-position" />
    </div>

    <div class="space-top-130 space-top-70">
      <HomeSelectionSchedule />
    </div>

    <div class="space-top-130 space-top-70">
      <HomeQa :qaBoxList="qaBoxList" />
    </div>

    <div class="space-top-130 space-top-70">
      <HomeContact id="top-contact" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HomeTop from "@/components/Home/Top.vue";
import HomeRecommendation from "@/components/Home/Recommendation.vue";
import HomeWorkPlace from "@/components/Home/WorkPlace.vue";
import HomeWorkEnvironment from "@/components/Home/WorkEnvironment.vue";
import HomeWorkEnvironment2 from "@/components/Home/WorkEnvironment2.vue";
import HomeMessage from "@/components/Home/Message.vue";
import HomeOurBuisiness from "@/components/Home/OurBuisiness.vue";
import HomeOneDay from "@/components/Home/OneDay.vue";
import HomeOurBuisiness2 from "@/components/Home/OurBuisiness2.vue";
import HomeOpenPositions from "@/components/Home/OpenPositions.vue";
import HomeSelectionSchedule from "@/components/Home/SelectionSchedule.vue";
import HomeQa from "@/components/Home/Qa.vue";
import HomeContact from "@/components/Home/Contact.vue";

export default {
  name: "Home",
  components: {
    HomeTop,
    HomeRecommendation,
    HomeWorkPlace,
    HomeWorkEnvironment,
    HomeWorkEnvironment2,
    HomeMessage,
    HomeOurBuisiness,
    HomeOneDay,
    HomeOurBuisiness2,
    HomeOpenPositions,
    HomeSelectionSchedule,
    HomeQa,
    HomeContact,
  },
  props: {},
  data() {
    return {
      qaBoxList: [
        {
          id: 1,
          qestion: "自由出勤の仕組みについて教えてください。自由に休めますか？",
          answer:
            "他の従業員と業務の進捗への影響がないことを前提にした勤務時間の自由となります。入社時に、1週間あたりの勤務時間（週12時間～）を決定します。この勤務時間において、出勤曜日と勤務時間が自由という仕組みです。出勤予定日にお休みをとった場合は、原則として1週間以内に欠勤分の勤務時間を補填する勤務をしていただいています（欠勤の理由と業務状況によっては補填なしの場合もあり）。お子様の長期休暇時は、事前の申請と調整によって、勤務日数・勤務時間を通常よりも減らすことは可能です。この条件を満たせる方のみご応募可能となっています。",
        },
        {
          id: 2,
          qestion: "業務中に車を運転することはありますか？",
          answer:
            "職種によってはあります。頻度はまれです。社有車があります。ご自身のお車を使う場合は手当を支給しています。業務中にお車の運転ができないことを前提とした応募も可能ですので、応募書類にその旨をご記載ください。",
        },
        {
          id: 3,
          qestion: "電車通勤は可能ですか？",
          answer:
            "可能です。ただし、自動車通勤を推奨しているため、公共交通機関を利用した通勤の場合も、交通費はご自宅から会社までの距離に応じたガソリン代を基準に支給されます。",
        },
        {
          id: 4,
          qestion: "副業・フリーランスでの応募も可能でしょうか？",
          answer:
            "平日9時30分～17時のご希望の曜日・時間帯で週12時間以上の勤務ができる場合は応募可能です。",
        },
        {
          id: 5,
          qestion: "在宅ワーク・テレワークは可能でしょうか？",
          answer:
            "事務所に出社しての勤務となっています。緊急事態宣言時などのみ行う場合があり、在宅ワークは原則としてございません。プログラマー職のみ、勤務評価によって在宅勤務を部分的に許可する場合があります。",
        },
        {
          id: 6,
          qestion: "グレイトヘルプに勤務しながら、副業は可能でしょうか？",
          answer:
            "可能です。ただし、弊社での勤務がメインであることを前提とします。また、弊社の事業内容と競合する仕事などは制限がある場合がございます。",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
.about-us {
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
  position: relative;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    width: 100%;
  }
  .left {
    width: 20%;
    @media screen and (max-width: 767px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .title {
      color: var(--main);
      font-size: 18px!important;
    }
    .blue {
      font-size: 65px;
      font-weight: 500;
      margin-bottom: 30px;
      @media screen and (min-width: 768px) and (max-width: 1150px) {
        font-size: 55px;
      }
    }
  }
  .right {
    width: 70%;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 50px;
      margin-bottom: 130px;
    }
  }
  .illust-a {
    width: 200px;
    position: absolute;
    bottom: 0;
    left: calc(20% - 100px);
    @media screen and (max-width: 767px) {
      left: 43%;
      overflow: hidden;
    }
  }
  .illust-b {
    width: 200px;
    position: absolute;
    top: -15%;
    left: -13%;
    @media screen and (max-width: 767px) {
      top: 78%;
      left: 0;
    }
  }
}
.recommendation {
  background-color: var(--sub);
}
.pala-top{
margin-top: 0!important;
}
</style>