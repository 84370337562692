<template>
  <div class="home-qa wrap">
    <div class="title-cover">
    <div class="title">
      <UiHeading
        :h="'2'"
        :type="'A'"
        :text="'Q&A'"
        :subTitle="'求人についてよくある質問'"
        class="heading"
      />
      <img alt="疑問" src="@/assets/ezgif.com-gif-maker-D.png" />
    </div>
    </div>
    <div class="content-wrap">
      <UiQaQaBox
        v-for="qaBoxListData in qaBoxList"
        :key="qaBoxListData.id"
        :qestion="qaBoxListData.qestion"
        :answer="qaBoxListData.answer"
        class="item"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "../Ui/Heading.vue";
import UiQaQaBox from "../Ui/Qa/QaBox.vue";
export default {
  name: "HomeQa",
  components: {
    UiHeading,
    UiQaQaBox,
  },
  props: {
    path: String,
    qaBoxList: Array,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.home-qa {
  .title-cover{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-left: 20%;
    @media screen and(max-width: 767px){
      flex-direction: column-reverse;
      align-items: center;
      padding-left: 0;
    }

    .heading {
      margin-bottom: 50px;
    }
    img {
      width: 30%;
      margin-bottom: 80px;
      @media screen and(max-width: 767px) {
        margin-bottom: 0;
        margin-left: 20%;
        width: 40%;
      }
    }
  }
}
.item {
  margin-bottom: 30px;
}
</style>
