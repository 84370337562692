var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-work-place"},[_c('UiHeading',{attrs:{"h":'2',"type":'A',"text":'Our Workplace',"subTitle":'こんな勤務条件・職場環境です'}}),_c('div',{directives:[{name:"prlx",rawName:"v-prlx.mobile.background",value:({
      direction: 'y',
      fromBottom: false,
      speed: 0.02,
    }),expression:"{\n      direction: 'y',\n      fromBottom: false,\n      speed: 0.02,\n    }",modifiers:{"mobile":true,"background":true}}],staticClass:"parallax_content1"},[_c('div',{staticClass:"wrap"},[_c('UiHeading',{attrs:{"h":'4',"type":'E2',"text":'勤務に関すること'}}),_c('div',{staticClass:"box-cover"},_vm._l((_vm.boxes),function(box){return _c('UiBox1',{key:box.id,staticClass:"box",attrs:{"imgPath":box.imgPath,"subTitle":box.subTitle,"text":box.text}})}),1),_vm._m(0),_vm._m(1)],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('div',{staticClass:"text"},[_c('p',[_vm._v(" 仕事における時間的な自由を大切に考え、勤務の自由度を高くし、無駄な拘束時間を可能な限りカットしています。「会社に要する時間は最小に、ご自身の時間を最大に」が弊社の方針です。 ")]),_c('p',[_vm._v(" 社内のメンバーの仲はとても良いですが、プライベートとは完全に切り分けられています。そのため、出勤前にカフェに行く、仕事終わりに従業員同士で会話をするという文化もなければ、週末に会社の人に飲みや遊びに誘われ、時間を失うということもありません（「会社の人とプライベートで仲良くしたい、遊びたい」という人は合わない職場です）。 ")]),_c('p',[_vm._v(" 働く誰もが仕事に集中し、プライベートに極力時間を使える環境となっています。 ")])]),_c('img',{attrs:{"src":require("@/assets/GH-kyujin-top-illustration011.webp"),"alt":"説明イラスト"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-free"},[_c('p',[_vm._v("※「勤務時間が自由」について")]),_c('p',[_vm._v(" 他の従業員と業務の進捗への影響がないことを前提にした勤務時間の自由となります。入社時に、1週間あたりの勤務時間（週12時間～）を決定します。この勤務時間において、出勤曜日と勤務時間が自由という仕組みです。出勤予定日にお休みをとった場合は、原則として1週間以内に欠勤分の勤務時間を補填する勤務をしていただいています（欠勤の理由と業務状況によっては補填なしの場合もあり）。お子様の長期休暇時は、事前の申請と調整によって、勤務日数・勤務時間を通常よりも減らすことは可能です。 ")])])
}]

export { render, staticRenderFns }