<template>
  <div class="home-our-buisiness">
    <div
      class="parallax_content1"
      v-prlx.mobile.background="{
        direction: 'y',
        fromBottom: false,
        speed: 0.02,
      }"
    >
      <div class="wrap">
        <UiHeading
          :h="'2'"
          :type="'A'"
          :text="'Work Policy'"
          :subTitle="'仕事の方針'"
          class="title"
        />
        <div class="block">
          <div class="text">
            <h5>■仕事のスピードよりクオリティ重視</h5>
            <p>
              弊社の価値はお客様に「結果」を提供することです。お客様も「結果」を期待して弊社に仕事を依頼します。そのため、下請けのような扱いで「低コストで早く作って」というお仕事はほとんどなく、パートナーという立ち位置で、結果につながるクオリティが必要になるお仕事がメインです。「仕事に追われる」という感覚なくお仕事ができます。また、仕事のスタンスとして、「雑で早い」ではなく、「適切な時間をかけ、正確であること」を大切にしています。
            </p>
            <h5>■上司からの詳しい指示とマニュアルがある</h5>
            <p>
              弊社の業務の多くはマニュアル化されています。また、上司からの詳しい指示があります。さらに、上司からのフォローやフィードバックも丁寧に行っています。パート勤務の方については、制作物を「独創的に作る」というよりも、「指示のとおりにしっかりと作る」という業務をしていただくことが主となります。
            </p>
          </div>
          <img
            src="@/assets/GH-kyujin-top-illustration012.webp"
            alt="お仕事イメージ"
            class="stuff"
          />
        </div>
        <div class="block">
          <img
            src="@/assets/GH-kyujin-top-illustration004.webp"
            alt="お仕事イメージ"
          />
          <div class="text">
            <h5>■事務所内で仕事に集中するデスクワーク</h5>
            <p>
              パート勤務の方は、事務所内でのデスクワークのお仕事です。社内メンバーでの打ち合わせはありますが、クライアントとの打ち合わせなど外出は基本的になく、事務所内で仕事に集中していただきます。
            </p>
            <h5>■学べる環境がある</h5>
            <p>
              社内には書籍が1,000冊以上あり、レンタル可能です。また、担当業務に関連する書籍は会社で購入します。会社として、売上アップや集客などのマーケティングに強いため、業務を通してこれらの知識も身につけることができます。ただ形として制作物を作るだけではなく、マーケティングの思考を持った技術者になることができます。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "../Ui/Heading.vue";
export default {
  name: "home-our-buisiness",
  components: {
    UiHeading,
  },
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.home-our-buisiness {
  .parallax_content1 {
    background-image: url(~@/assets/GH-kyujin-top-back003.webp);
    background-size: cover;
    margin-top: 50px;
    padding: 100px 0;
    @media screen and (max-width: 768px) {
      background-image:none;
      background-color: #f0f0f0;
      // padding-top: 0px;
    }
  }
  .title {
    margin-bottom: 50px;
  }
  .block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media screen and (max-width: 767px){
      display: block;
    }
    .text {
      width: 75%;
      @media screen and (max-width: 767px){
        width: 100%;
      }
      h5 {
        color: var(--sub);
        font-size: 25px;
        margin-bottom: 10px;
        @media screen and (max-width: 767px){
          font-size: 20px;
        }
      }
      p {
        margin-bottom: 30px;
      }
    }
    img {
      width: 200px;
      @media screen and (max-width: 767px){
        width: 50%;
      margin-left: 50%;
      }
    }
    .stuff{
      @media screen and (max-width: 767px){
        display: none;
      }
    }
  }
}
</style>
