import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  setTitle,
  setDescription
} from '@/mixins/index.js'

import Home from '../views/Home.vue'
import Programmer from '../views/Programmer.vue'
import ProgrammerFirst from '../views/ProgrammerFirst.vue'
import WebCoder from '../views/WebCoder.vue'
import WebCoderFirst from '../views/WebCoderFirst.vue'
import WebDesigner from '../views/WebDesigner.vue'
import Designer from '../views/Designer.vue'
import MarketingSupport from '../views/MarketingSupport.vue'
import AdSupport from '../views/AdSupport.vue'
import OfficeWork from '../views/OfficeWork.vue'
import OfficeWorkKeiri from '../views/OfficeWorkKeiri.vue'
// import Contact from '../views/Contact.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: '株式会社グレイトヘルプ パート・アルバイト求人サイト',
      description: '岐阜市にある株式会社グレイトヘルプのパート・アルバイトの求人・採用情報です。ウェブサイト制作、プログラマー、デザイナー、マーケティングサポート、事務などの職種を募集しています。出勤曜日と勤務時間が自由など、家事と子育てやプライベートに忙しい主婦、副業での勤務の方、個人事業主としての活動の中の1つとしてお仕事をされる方にとって働きやすい職場となっています。'
    }
  },
  {
    path: '/pg/',
    name: 'programmer',
    component: Programmer,
    meta: {
      title: 'プログラマー(勤務時間自由・岐阜市)のパート・アルバイトの求人・採用',
      description: '岐阜市にある株式会社グレイトヘルプのプログラマー・システム開発のパート・アルバイトの求人・採用情報です。出勤曜日と勤務時間が自由のため、主婦の方、副業の方、個人事業主の方が活躍しています。勤務評価によって在宅勤務を許可しています。仕事は、PHP・Javascript、Laravel、Cakeなどのウェブ系のプログラミングで、クライアントからの受託開発案件と自社サービスの開発案件があります。',
      gfjScriptContent: `{
        "@context" : "http://schema.org/",
        "@type" : "JobPosting",
        "title" : "プログラマー（経験者）",
        "description" : 
      "<p>■仕事内容<br>
      ウェブシステムのプログラミング業務がメインの仕事です。HTML・CSS、Javascript、PHP、MySQLなどのプログラムをしていただきます。クライアントからの受託案件と、自社サービスの案件があります。その他、事務作業や雑務もあります。</p>
      <p>■応募資格<br>
      プログラミングの実務経験3年以上。<br>
      責任感を持って仕事に取り組んでいただける方、歓迎します！</p>
      <p>■勤務時間<br>
      平日9時半～17時の時間帯で、ご自由に調整していただくことができます。<br>
      ※1週間あたり15時間以上勤務することが条件です。</p>
      <p>■グレイトヘルプはこんな職場です<br>
      グレイトヘルプは、パートタイム勤務の従業員が働きやすい職場環境づくりに努めています。自由シフト出勤、無駄な拘束時間なし、ドリンクや軽食の支給など、働きやすさが自慢です。自由シフト出勤は、家事や育児、プライベートとの両立が可能で、もっとも好評な制度となっています。スキルや経験はあるけど、労働時間の問題で良い仕事が見つからないという方にピッタリな職場です。</p>
      
      <p>◎自由シフト出勤、無駄な拘束時間なし<br>
      ◎ドリンク、軽食の支給<br>
      ◎有給制度(パートタイムの方にも有給を支給しています)<br>
      ◎常備薬完備(頭痛薬や風邪薬など)<br>
      ◎パソコンは全てブルーライトカット<br>
      ◎服装、髪型、ネイル自由<br>
      ◎書籍や教材が充実<br>
      ◎男女トイレ別<br>
      ◎ペーパータオルで衛生的</p>
      ",
      
      
    "identifier": {
    "@type": "PropertyValue",
    "name": "株式会社グレイトヘルプ",
    "value": "01"
    },
      
    "datePosted" : "2023-02-15",
      
    "validThrough" : "2024-05-22",
      
    "employmentType" : "PART_TIME",
      
    "hiringOrganization":{
        "@type":"Organization",
        "name":"株式会社グレイトヘルプ",
        "sameAs":"https://greathelp.co.jp",
        "logo":" https://greathelp.co.jp/wp202007/wp-content/themes/gh/img/GH-logo-mark.png"
        },
      
      
      "jobLocation":{
        "@type":"Place",
        "address":{
        "@type":"PostalAddress",
        "addressCountry":"JP",
          "postalCode":"500-8468",
          "addressRegion":"岐阜県",
          "addressLocality":"岐阜市",
          "streetAddress":"加納桜田町2-5-2"}
        },
        
      "baseSalary": {
          "@type": "MonetaryAmount",
          "currency": "JPY",
          "value": {
            "@type": "QuantitativeValue",
            "value": 1400, 
      "minValue":1400,                                          
            "maxValue":2000,                                         
            "unitText": "HOUR"
          }
        }
      }
        "workHours": "9:00～18:00(自由出勤制)</p>】" ,`
    }
  },
  {
    path: '/pg01/',
    name: 'programmerFirst',
    component: ProgrammerFirst,
    meta: {
      title: 'プログラマー(未経験者～経験3年未満・岐阜市)のパート・アルバイトの求人・採用',
      description: '未経験者～経験3年未満を対象にした、株式会社グレイトヘルプのプログラマーのパート・アルバイトの求人・採用情報です。実務経験がない、または、浅いがウェブプログラミング（HTML・CSS・PHP・Javascript）のスキルがある人が仕事を通して実務経験を積める職場です。勤務曜日と勤務時間は自由、時給は1分単位で計上しています。',
      gfjScriptContent: `{
        "@context" : "http://schema.org/",
        "@type" : "JobPosting",
        "title" : "プログラマー（未経験者）",
        "description" : 
      "<p>■仕事内容<br>
      ウェブシステムのプログラミング業務がメインの仕事です。HTML・CSS、Javascript、PHP、MySQLなどのプログラムをしていただきます。クライアントからの受託案件と、自社サービスの案件があります。その他、事務作業や雑務もあります。</p>
      <p>■応募資格<br>
      責任感を持って仕事に取り組んでいただける方、歓迎します！</p>
      <p>■勤務時間<br>
      平日9時半～17時の時間帯で、ご自由に調整していただくことができます。<br>
      ※1週間あたり15時間以上勤務することが条件です。</p>
      <p>■グレイトヘルプはこんな職場です<br>
      グレイトヘルプは、パートタイム勤務の従業員が働きやすい職場環境づくりに努めています。自由シフト出勤、無駄な拘束時間なし、ドリンクや軽食の支給など、働きやすさが自慢です。自由シフト出勤は、家事や育児、プライベートとの両立が可能で、もっとも好評な制度となっています。スキルや経験はあるけど、労働時間の問題で良い仕事が見つからないという方にピッタリな職場です。</p>
      
      <p>◎自由シフト出勤、無駄な拘束時間なし<br>
      ◎ドリンク、軽食の支給<br>
      ◎有給制度(パートタイムの方にも有給を支給しています)<br>
      ◎常備薬完備(頭痛薬や風邪薬など)<br>
      ◎パソコンは全てブルーライトカット<br>
      ◎服装、髪型、ネイル自由<br>
      ◎書籍や教材が充実<br>
      ◎男女トイレ別<br>
      ◎ペーパータオルで衛生的</p>
      ",
      
      
    "identifier": {
    "@type": "PropertyValue",
    "name": "株式会社グレイトヘルプ",
    "value": "01"
    },
      
    "datePosted" : "2023-02-15",
      
    "validThrough" : "2024-05-22",
      
    "employmentType" : "PART_TIME",
      
    "hiringOrganization":{
        "@type":"Organization",
        "name":"株式会社グレイトヘルプ",
        "sameAs":"https://greathelp.co.jp",
        "logo":" https://greathelp.co.jp/wp202007/wp-content/themes/gh/img/GH-logo-mark.png"
        },
      
      
      "jobLocation":{
        "@type":"Place",
        "address":{
        "@type":"PostalAddress",
        "addressCountry":"JP",
          "postalCode":"500-8468",
          "addressRegion":"岐阜県",
          "addressLocality":"岐阜市",
          "streetAddress":"加納桜田町2-5-2"}
        },
        
      "baseSalary": {
          "@type": "MonetaryAmount",
          "currency": "JPY",
          "value": {
            "@type": "QuantitativeValue",
            "value": 1400, 
      "minValue":1400,                                          
            "maxValue":2000,                                         
            "unitText": "HOUR"
          }
        }
      }
        "workHours": "9:00～18:00(自由出勤制)</p>】" ,`
    }
  },
  {
    path: '/web/',
    name: 'webCoder',
    component: WebCoder,
    meta: {
      title: 'ウェブサイト制作(勤務時間自由・岐阜市)のパート・アルバイトの求人・採用',
      description: '岐阜市にある株式会社グレイトヘルプのホームページ・ウェブサイト制作（コーディング）のパート・アルバイトの求人・採用情報です。マーケティングに強みがある会社のため、マーケティングや集客の技術も仕事を通して身につけることができます。出勤曜日と勤務時間が自由のため、主婦の方、副業の方、個人事業主の方が活躍しています。',
      gfjScriptContent: `{
        "@context" : "http://schema.org/",
        "@type" : "JobPosting",
        "title" : "ウェブサイト制作（未経験者）",
        "description" : 
      "<p>■仕事内容<br>
      </p>
      <p>■応募資格<br>
      弊社では、クライアントからの受託開発系のホームページ制作と、自社サービスの販売用のホームページの制作の業務があります。HTML/CSSによるホームページのコーディングやWordPressでのホームページの構築をしていただきます。業務の指示として、マーケティング部門がホームページの設計図と文章を用意し、それをもとにデザイナーがホームページのデザインをし、これらを用いて、ホームページをコーディングして形にしていただきます。<br>
      責任感を持って仕事に取り組んでいただける方、歓迎します！</p>
      <p>■勤務時間<br>
      平日9時半～17時の時間帯で、ご自由に調整していただくことができます。<br>
      ※1週間あたり15時間以上勤務することが条件です。</p>
      <p>■グレイトヘルプはこんな職場です<br>
      グレイトヘルプは、パートタイム勤務の従業員が働きやすい職場環境づくりに努めています。自由シフト出勤、無駄な拘束時間なし、ドリンクや軽食の支給など、働きやすさが自慢です。自由シフト出勤は、家事や育児、プライベートとの両立が可能で、もっとも好評な制度となっています。スキルや経験はあるけど、労働時間の問題で良い仕事が見つからないという方にピッタリな職場です。</p>
      
      <p>◎自由シフト出勤、無駄な拘束時間なし<br>
      ◎ドリンク、軽食の支給<br>
      ◎有給制度(パートタイムの方にも有給を支給しています)<br>
      ◎常備薬完備(頭痛薬や風邪薬など)<br>
      ◎パソコンは全てブルーライトカット<br>
      ◎服装、髪型、ネイル自由<br>
      ◎書籍や教材が充実<br>
      ◎男女トイレ別<br>
      ◎ペーパータオルで衛生的</p>
      ",
      
      
    "identifier": {
    "@type": "PropertyValue",
    "name": "株式会社グレイトヘルプ",
    "value": "02"
    },
      
    "datePosted" : "2023-02-15",
      
    "validThrough" : "2024-05-22",
      
    "employmentType" : "PART_TIME",
      
    "hiringOrganization":{
        "@type":"Organization",
        "name":"株式会社グレイトヘルプ",
        "sameAs":"https://greathelp.co.jp",
        "logo":" https://greathelp.co.jp/wp202007/wp-content/themes/gh/img/GH-logo-mark.png"
        },
      
      
      "jobLocation":{
        "@type":"Place",
        "address":{
        "@type":"PostalAddress",
        "addressCountry":"JP",
          "postalCode":"500-8468",
          "addressRegion":"岐阜県",
          "addressLocality":"岐阜市",
          "streetAddress":"加納桜田町2-5-2"}
        },
        
      "baseSalary": {
          "@type": "MonetaryAmount",
          "currency": "JPY",
          "value": {
            "@type": "QuantitativeValue",
            "value": 1200, 
      "minValue":1200,                                          
            "maxValue":2000,                                         
            "unitText": "HOUR"
          }
        }
      }
        "workHours": "9:00～18:00(自由出勤制)</p>】" ,`

    }
  },
  {
    path: '/web01/',
    name: 'webCoderFirst',
    component: WebCoderFirst,
    meta: {
      title: 'ウェブサイト制作(未経験者～経験2年未満・岐阜市)のパート・アルバイトの求人・採用',
      description: '未経験者～経験2年未満を対象にした、株式会社グレイトヘルプのホームページ・ウェブサイト制作（コーディング）のパート・アルバイトの求人・採用情報です。実務経験がない、または、浅いがウェブサイト制作（HTML/CSS）のスキルがある人が仕事を通して実務経験を積める職場です。勤務曜日と勤務時間は自由、時給は1分単位で計上しています。',
      gfjScriptContent: `{
        "@context" : "http://schema.org/",
        "@type" : "JobPosting",
        "title" : "ウェブサイト制作（経験者）",
        "description" : 
      "<p>■仕事内容<br>
      </p>
      <p>■応募資格<br>
      弊社では、クライアントからの受託開発系のホームページ制作と、自社サービスの販売用のホームページの制作の業務があります。HTML/CSSによるホームページのコーディングやWordPressでのホームページの構築をしていただきます。業務の指示として、マーケティング部門がホームページの設計図と文章を用意し、それをもとにデザイナーがホームページのデザインをし、これらを用いて、ホームページをコーディングして形にしていただきます。<br>
      責任感を持って仕事に取り組んでいただける方、歓迎します！</p>
      <p>■勤務時間<br>
      平日9時半～17時の時間帯で、ご自由に調整していただくことができます。<br>
      ※1週間あたり15時間以上勤務することが条件です。</p>
      <p>■グレイトヘルプはこんな職場です<br>
      グレイトヘルプは、パートタイム勤務の従業員が働きやすい職場環境づくりに努めています。自由シフト出勤、無駄な拘束時間なし、ドリンクや軽食の支給など、働きやすさが自慢です。自由シフト出勤は、家事や育児、プライベートとの両立が可能で、もっとも好評な制度となっています。スキルや経験はあるけど、労働時間の問題で良い仕事が見つからないという方にピッタリな職場です。</p>
      
      <p>◎自由シフト出勤、無駄な拘束時間なし<br>
      ◎ドリンク、軽食の支給<br>
      ◎有給制度(パートタイムの方にも有給を支給しています)<br>
      ◎常備薬完備(頭痛薬や風邪薬など)<br>
      ◎パソコンは全てブルーライトカット<br>
      ◎服装、髪型、ネイル自由<br>
      ◎書籍や教材が充実<br>
      ◎男女トイレ別<br>
      ◎ペーパータオルで衛生的</p>
      ",
      
      
    "identifier": {
    "@type": "PropertyValue",
    "name": "株式会社グレイトヘルプ",
    "value": "02"
    },
      
    "datePosted" : "2023-02-15",
      
    "validThrough" : "2024-05-22",
      
    "employmentType" : "PART_TIME",
      
    "hiringOrganization":{
        "@type":"Organization",
        "name":"株式会社グレイトヘルプ",
        "sameAs":"https://greathelp.co.jp",
        "logo":" https://greathelp.co.jp/wp202007/wp-content/themes/gh/img/GH-logo-mark.png"
        },
      
      
      "jobLocation":{
        "@type":"Place",
        "address":{
        "@type":"PostalAddress",
        "addressCountry":"JP",
          "postalCode":"500-8468",
          "addressRegion":"岐阜県",
          "addressLocality":"岐阜市",
          "streetAddress":"加納桜田町2-5-2"}
        },
        
      "baseSalary": {
          "@type": "MonetaryAmount",
          "currency": "JPY",
          "value": {
            "@type": "QuantitativeValue",
            "value": 1200, 
      "minValue":1200,                                          
            "maxValue":2000,                                         
            "unitText": "HOUR"
          }
        }
      }
        "workHours": "9:00～18:00(自由出勤制)</p>】" ,`

    }
  },
  {
    path: '/web-design/',
    name: 'webDesigner',
    component: WebDesigner,
    meta: {
      title: 'ウェブデザイナー(勤務時間自由・岐阜市)のパート・アルバイトの求人・採用',
      description: '岐阜市にある株式会社グレイトヘルプのウェブデザイナーのパート・アルバイトの求人・採用情報です。ホームページ・ウェブサイトのコーディングとデザインの両方の業務を担当し、サイトを完成させるお仕事です。出勤曜日と勤務時間が自由など、家事と子育てやプライベートに忙しい主婦、副業での勤務の方、個人事業主としての活動の中の1つとしてお仕事をされる方にとって働きやすい職場となっています。',
      gfjScriptContent: `{
        "@context" : "http://schema.org/",
        "@type" : "JobPosting",
        "title" : "ウェブデザイナー（経験者）",
        "description" : 
      "<p>■仕事内容<br>
      弊社では、クライアントからの受託制作系のホームページ制作と、自社サービスの販売用のホームページの制作の業務があります。<br>ホームページのデザインとコーディングの両方をしていただきます。<br>マーケティング部門がホームページの設計図と文章を用意し、それをもとにホームページのデザインをIllustratorなどのデザインソフトでし、ホームページをコーディングして形にしていただきます。このように、デザインとコーディングの両方を行い、ホームページを完成させていただきます。<br>その他にも、広告用のバナーのデザインや、紙媒体のデザインの業務もしていただきます。マーケティング部門からの指示書にもとづいてデザインをするお仕事です。
      </p>
      <p>■応募資格<br>
      デザインとHTML/CSSのスキルは必須です。WordPressのスキルや経験もあると良いです。ウェブで再現できるデザインができることは必須で、デザインとコーディングの両方に対応していただきます。<br>
      責任感を持って仕事に取り組んでいただける方、歓迎します！</p>
      <p>■勤務時間<br>
      平日9時半～17時の時間帯で、ご自由に調整していただくことができます。<br>
      ※1週間あたり15時間以上勤務することが条件です。</p>
      <p>■グレイトヘルプはこんな職場です<br>
      グレイトヘルプは、パートタイム勤務の従業員が働きやすい職場環境づくりに努めています。自由シフト出勤、無駄な拘束時間なし、ドリンクや軽食の支給など、働きやすさが自慢です。自由シフト出勤は、家事や育児、プライベートとの両立が可能で、もっとも好評な制度となっています。スキルや経験はあるけど、労働時間の問題で良い仕事が見つからないという方にピッタリな職場です。</p>
      
      <p>◎自由シフト出勤、無駄な拘束時間なし<br>
      ◎ドリンク、軽食の支給<br>
      ◎有給制度(パートタイムの方にも有給を支給しています)<br>
      ◎常備薬完備(頭痛薬や風邪薬など)<br>
      ◎パソコンは全てブルーライトカット<br>
      ◎服装、髪型、ネイル自由<br>
      ◎書籍や教材が充実<br>
      ◎男女トイレ別<br>
      ◎ペーパータオルで衛生的</p>
      ",
      
      
    "identifier": {
    "@type": "PropertyValue",
    "name": "株式会社グレイトヘルプ",
    "value": "03"
    },
      
    "datePosted" : "2023-02-15",
      
    "validThrough" : "2024-05-22",
      
    "employmentType" : "PART_TIME",
      
    "hiringOrganization":{
        "@type":"Organization",
        "name":"株式会社グレイトヘルプ",
        "sameAs":"https://greathelp.co.jp",
        "logo":" https://greathelp.co.jp/wp202007/wp-content/themes/gh/img/GH-logo-mark.png"
        },
      
      
      "jobLocation":{
        "@type":"Place",
        "address":{
        "@type":"PostalAddress",
        "addressCountry":"JP",
          "postalCode":"500-8468",
          "addressRegion":"岐阜県",
          "addressLocality":"岐阜市",
          "streetAddress":"加納桜田町2-5-2"}
        },
        
      "baseSalary": {
          "@type": "MonetaryAmount",
          "currency": "JPY",
          "value": {
            "@type": "QuantitativeValue",
            "value": 1250, 
      "minValue":1250,                                          
            "maxValue":2000,                                         
            "unitText": "HOUR"
          }
        }
      }
        "workHours": "9:00～18:00(自由出勤制)</p>】" ,`
    }
  },
  {
    path: '/design/',
    name: 'designer',
    component: Designer,
    meta: {
      title: 'デザイナー(勤務時間自由・岐阜市)のパート・アルバイトの求人・採用',
      description: '岐阜市にある株式会社グレイトヘルプのデザイナーのパート・アルバイトの求人・採用情報です。ウェブサイトの外観のデザイン（コーディング技術不問）名刺やチラシなど紙媒体のデザインなどを担当していただきます。出勤曜日と勤務時間が自由など、家事と子育てやプライベートに忙しい主婦、副業での勤務の方、個人事業主としての活動の中の1つとしてお仕事をされる方にとって働きやすい職場となっています。',
      gfjScriptContent: `{
        "@context" : "http://schema.org/",
        "@type" : "JobPosting",
        "title" : "デザイナー（経験者）",
        "description" : 
      "<p>■仕事内容<br>
      弊社のデザイン業務は主に、ウェブ系は、ホームページ、ネットショップ、広告用のバナーなどのデザイン業務。紙媒体系は、チラシ、名刺、封筒、ロゴなどのデザイン業務があります。他にも、撮影の立会や補助、紙媒体の印刷業務もあります。クライアントから媒体と販促物の制作業務のすべてを請け負っているケースが多いため、このようにありとあらゆるデザインとそれに関する業務を担当していただきます（保有するスキルと経験を考慮して業務を割り当てるため、全スキルを保有していなくても問題ありません）。
      </p>
      <p>■応募資格<br>
      IllustratorとPhotoshopを使用したデザインスキルと実務経験は必須です。紙媒体の印刷に関するスキルや経験もあると良いです。<br>ホームページやネットショップなど、ウェブ系のデザインについては、コーディングのスキルは不問です。その外観をデザインしていただきます。また、ウェブ媒体で再現できるデザインをする必要があるため、この論理やルールについては、入社後に教育します。<br>
      責任感を持って仕事に取り組んでいただける方、歓迎します！</p>
      <p>■勤務時間<br>
      平日9時～17時の時間帯で、ご自由に調整していただくことができます。<br>
      ※1週間あたり15時間以上勤務することが条件です。</p>
      <p>■グレイトヘルプはこんな職場です<br>
      グレイトヘルプは、パートタイム勤務の従業員が働きやすい職場環境づくりに努めています。自由シフト出勤、無駄な拘束時間なし、ドリンクや軽食の支給など、働きやすさが自慢です。自由シフト出勤は、家事や育児、プライベートとの両立が可能で、もっとも好評な制度となっています。スキルや経験はあるけど、労働時間の問題で良い仕事が見つからないという方にピッタリな職場です。</p>
      
      <p>◎自由シフト出勤、無駄な拘束時間なし<br>
      ◎ドリンク、軽食の支給<br>
      ◎有給制度(パートタイムの方にも有給を支給しています)<br>
      ◎常備薬完備(頭痛薬や風邪薬など)<br>
      ◎パソコンは全てブルーライトカット<br>
      ◎服装、髪型、ネイル自由<br>
      ◎書籍や教材が充実<br>
      ◎男女トイレ別<br>
      ◎ペーパータオルで衛生的</p>
      ",
      
      
    "identifier": {
    "@type": "PropertyValue",
    "name": "株式会社グレイトヘルプ",
    "value": "04"
    },
      
    "datePosted" : "2023-02-15",
      
    "validThrough" : "2024-05-22",
      
    "employmentType" : "PART_TIME",
      
    "hiringOrganization":{
        "@type":"Organization",
        "name":"株式会社グレイトヘルプ",
        "sameAs":"https://greathelp.co.jp",
        "logo":" https://greathelp.co.jp/wp202007/wp-content/themes/gh/img/GH-logo-mark.png"
        },
      
      
      "jobLocation":{
        "@type":"Place",
        "address":{
        "@type":"PostalAddress",
        "addressCountry":"JP",
          "postalCode":"500-8468",
          "addressRegion":"岐阜県",
          "addressLocality":"岐阜市",
          "streetAddress":"加納桜田町2-5-2"}
        },
        
      "baseSalary": {
          "@type": "MonetaryAmount",
          "currency": "JPY",
          "value": {
            "@type": "QuantitativeValue",
            "value": 1110, 
      "minValue":1100,                                          
            "maxValue":2000,                                         
            "unitText": "HOUR"
          }
        }
      }
        "workHours": "9:00～18:00(自由出勤制)</p>】" ,`
    }

  },
  {
    path: '/marketing/',
    name: 'marketingSupport',
    component: MarketingSupport,
    meta: {
      title: 'マーケティングサポート(勤務時間自由・岐阜市)のパート・アルバイトの求人・採用',
      description: '岐阜市にある株式会社グレイトヘルプのマーケティングサポートのパート・アルバイトの求人・採用情報です。ウェブサイトやSNSのマーケティングや集客業務のサポート業務がお仕事です。先輩社員が1から丁寧に指導します。出勤曜日と勤務時間が自由のため、主婦の方、副業の方、個人事業主の方の仕事の1つとして活躍しています。',
      gfjScriptContent: `{
        "@context" : "http://schema.org/",
        "@type" : "JobPosting",
        "title" : "マーケティングサポート",
        "description" : 
      "<p>■仕事内容<br>
      株式会社グレイトヘルプのマーケティングサポート職は、マーケティング部門の上司のサポート業務がメインです。
具体的には、ホームページやネットショップなど制作する媒体に関するマーケティングや調査業務、その設計、文章作成、完成品の確認などを行います。インターネット広告やSNSの運用のサポートやデータ集計業務なども行います。
      </p>
      <p>■応募資格<br>
      入社の段階で、パソコンの操作スキルは必須です。具体的には、ブラインドタッチでのタイピング、ExcelやWordなどの操作は必須です。また、コミュニケーション能力と管理能力も必要となります。<br>
      責任感を持って仕事に取り組んでいただける方、歓迎します！</p>
      <p>■勤務時間<br>
      平日9時～17時の時間帯で、ご自由に調整していただくことができます。<br>
      ※1週間あたり15時間以上勤務することが条件です。</p>
      <p>■グレイトヘルプはこんな職場です<br>
      グレイトヘルプは、パートタイム勤務の従業員が働きやすい職場環境づくりに努めています。自由シフト出勤、無駄な拘束時間なし、ドリンクや軽食の支給など、働きやすさが自慢です。自由シフト出勤は、家事や育児、プライベートとの両立が可能で、もっとも好評な制度となっています。スキルや経験はあるけど、労働時間の問題で良い仕事が見つからないという方にピッタリな職場です。</p>
      
      <p>◎自由シフト出勤、無駄な拘束時間なし<br>
      ◎ドリンク、軽食の支給<br>
      ◎有給制度(パートタイムの方にも有給を支給しています)<br>
      ◎常備薬完備(頭痛薬や風邪薬など)<br>
      ◎パソコンは全てブルーライトカット<br>
      ◎服装、髪型、ネイル自由<br>
      ◎書籍や教材が充実<br>
      ◎男女トイレ別<br>
      ◎ペーパータオルで衛生的</p>
      ",
      
      
    "identifier": {
    "@type": "PropertyValue",
    "name": "株式会社グレイトヘルプ",
    "value": "05"
    },
      
    "datePosted" : "2023-02-15",
      
    "validThrough" : "2024-05-22",
      
    "employmentType" : "PART_TIME",
      
    "hiringOrganization":{
        "@type":"Organization",
        "name":"株式会社グレイトヘルプ",
        "sameAs":"https://greathelp.co.jp",
        "logo":" https://greathelp.co.jp/wp202007/wp-content/themes/gh/img/GH-logo-mark.png"
        },
      
      
      "jobLocation":{
        "@type":"Place",
        "address":{
        "@type":"PostalAddress",
        "addressCountry":"JP",
          "postalCode":"500-8468",
          "addressRegion":"岐阜県",
          "addressLocality":"岐阜市",
          "streetAddress":"加納桜田町2-5-2"}
        },
        
      "baseSalary": {
          "@type": "MonetaryAmount",
          "currency": "JPY",
          "value": {
            "@type": "QuantitativeValue",
            "value": 1100, 
      "minValue":1100,                                          
            "maxValue":1500,                                         
            "unitText": "HOUR"
          }
        }
      }
        "workHours": "9:00～18:00(自由出勤制)</p>】" ,`
    }
  },
  {
    path: '/ad',
    name: 'adSupport/',
    component: AdSupport,
    meta: {
      title: '広告運用サポート(勤務時間自由・岐阜市)のパート・アルバイトの求人・採用',
      description: '岐阜市にある株式会社グレイトヘルプの一般事務のパート・アルバイトの求人・採用情報です。出勤曜日と勤務時間が自由で働きやすい職場です。時短勤務・扶養の範囲内での勤務ももちろんOKです。マニュアルにそった定例業務が中心のデスクワークです。責任感があり、正確な業務遂行ができる方のご応募をお待ちしています。',
      gfjScriptContent: `{
        "@context" : "http://schema.org/",
        "@type" : "JobPosting",
        "title" : "広告運用サポート",
        "description" : 
      "<p>■仕事内容<br>
      Google広告・Yahoo!広告・Facebook広告などの主要インターネット広告の運用業務、分析業務、運用するサイトの改善業務など、マーケティング部門の上司のサポートという立場で行っていただきます。
具体的には、運用している広告のデータをチェックし、広告やサイトの改善を行っていただきます。広告の運用以外にも、ウェブマーケティングに関する業務をマーケティング部門の上司の指示にそって行います。マーケティング的な調査業務、文章やキャッチコピーの作成、デザイン部門やプログラム部門への指示書の作成など、いろいろな業務があります。
      </p>
      <p>■応募資格<br>
      入社の段階で、パソコンの操作スキルは必須です。具体的には、ブラインドタッチでのタイピング、ExcelやWordなどの操作は必須です。また、コミュニケーション能力と管理能力も必要となります。<br>
      責任感を持って仕事に取り組んでいただける方、歓迎します！</p>
      <p>■勤務時間<br>
      平日9時半～17時の時間帯で、ご自由に調整していただくことができます。<br>
      ※1週間あたり15時間以上勤務することが条件です。</p>
      <p>■グレイトヘルプはこんな職場です<br>
      グレイトヘルプは、パートタイム勤務の従業員が働きやすい職場環境づくりに努めています。自由シフト出勤、無駄な拘束時間なし、ドリンクや軽食の支給など、働きやすさが自慢です。自由シフト出勤は、家事や育児、プライベートとの両立が可能で、もっとも好評な制度となっています。スキルや経験はあるけど、労働時間の問題で良い仕事が見つからないという方にピッタリな職場です。</p>
      
      <p>◎自由シフト出勤、無駄な拘束時間なし<br>
      ◎ドリンク、軽食の支給<br>
      ◎有給制度(パートタイムの方にも有給を支給しています)<br>
      ◎常備薬完備(頭痛薬や風邪薬など)<br>
      ◎パソコンは全てブルーライトカット<br>
      ◎服装、髪型、ネイル自由<br>
      ◎書籍や教材が充実<br>
      ◎男女トイレ別<br>
      ◎ペーパータオルで衛生的</p>
      ",
      
      
    "identifier": {
    "@type": "PropertyValue",
    "name": "株式会社グレイトヘルプ",
    "value": "06"
    },
      
    "datePosted" : "2023-02-15",
      
    "validThrough" : "2024-05-22",
      
    "employmentType" : "PART_TIME",
      
    "hiringOrganization":{
        "@type":"Organization",
        "name":"株式会社グレイトヘルプ",
        "sameAs":"https://greathelp.co.jp",
        "logo":" https://greathelp.co.jp/wp202007/wp-content/themes/gh/img/GH-logo-mark.png"
        },
      
      
      "jobLocation":{
        "@type":"Place",
        "address":{
        "@type":"PostalAddress",
        "addressCountry":"JP",
          "postalCode":"500-8468",
          "addressRegion":"岐阜県",
          "addressLocality":"岐阜市",
          "streetAddress":"加納桜田町2-5-2"}
        },
        
      "baseSalary": {
          "@type": "MonetaryAmount",
          "currency": "JPY",
          "value": {
            "@type": "QuantitativeValue",
            "value": 1100, 
      "minValue":1100,                                          
            "maxValue":1500,                                         
            "unitText": "HOUR"
          }
        }
      }
        "workHours": "9:00～18:00(自由出勤制)</p>】" ,`
    }
  },
  {
    path: '/keiri/',
    name: 'OfficeWorkKeiri',
    component: OfficeWorkKeiri,
    meta: {
      title: '経理(勤務時間自由・岐阜市)のパート・アルバイトの求人・採用',
      description: '岐阜市にある株式会社グレイトヘルプの経理のパート・アルバイトの求人・採用情報です。出勤曜日と勤務時間が自由で働きやすい職場です。時短勤務・扶養の範囲内での勤務ももちろんOKです。経理業務全般を担当していただきます。責任感があり、正確な業務遂行ができる方のご応募をお待ちしています。',
      gfjScriptContent: `{
        "@context" : "http://schema.org/",
        "@type" : "JobPosting",
        "title" : "経理（経験者）",
        "description" : 
      "<p>■仕事内容<br>
      一般的な経理事務の業務内容です。基本的に業務をマニュアル化しているので、マニュアルにそって、正確に業務を遂行していただくお仕事になります。経理の業務の他に、社内の管理に関する事務業務、社外の手続きなどに関する事務業務、雑務などのお仕事もあります。仕事のほとんどがデスクワークです。
      </p>
      <p>■応募資格<br>
      入社の段階で、経理の経験とパソコンスキル（タイピング、Excel・Wordの経験）は必須です。経理の担当として、経理業務の全般をお願いします。しかしながら、業務の多くをマニュアル化していて、一般事務の従業員にて経理業務をすべて担当することもできるため、決して難しい経理のお仕事ではありません。大切なのは、正確に業務を遂行していただくことです。
      <p>■勤務時間<br>
      平日9時半～17時の時間帯で、ご自由に調整していただくことができます。<br>
      ※1週間あたり15時間以上勤務することが条件です。</p>
      <p>■グレイトヘルプはこんな職場です<br>
      グレイトヘルプは、パートタイム勤務の従業員が働きやすい職場環境づくりに努めています。自由シフト出勤、無駄な拘束時間なし、ドリンクや軽食の支給など、働きやすさが自慢です。自由シフト出勤は、家事や育児、プライベートとの両立が可能で、もっとも好評な制度となっています。スキルや経験はあるけど、労働時間の問題で良い仕事が見つからないという方にピッタリな職場です。</p>
      
      <p>◎自由シフト出勤、無駄な拘束時間なし<br>
      ◎ドリンク、軽食の支給<br>
      ◎有給制度(パートタイムの方にも有給を支給しています)<br>
      ◎常備薬完備(頭痛薬や風邪薬など)<br>
      ◎パソコンは全てブルーライトカット<br>
      ◎服装、髪型、ネイル自由<br>
      ◎書籍や教材が充実<br>
      ◎男女トイレ別<br>
      ◎ペーパータオルで衛生的</p>
      ",
      
      
    "identifier": {
    "@type": "PropertyValue",
    "name": "株式会社グレイトヘルプ",
    "value": "07"
    },
      
    "datePosted" : "2023-02-15",
      
    "validThrough" : "2024-05-22",
      
    "employmentType" : "PART_TIME",
      
    "hiringOrganization":{
        "@type":"Organization",
        "name":"株式会社グレイトヘルプ",
        "sameAs":"https://greathelp.co.jp",
        "logo":" https://greathelp.co.jp/wp202007/wp-content/themes/gh/img/GH-logo-mark.png"
        },
      
      
      "jobLocation":{
        "@type":"Place",
        "address":{
        "@type":"PostalAddress",
        "addressCountry":"JP",
          "postalCode":"500-8468",
          "addressRegion":"岐阜県",
          "addressLocality":"岐阜市",
          "streetAddress":"加納桜田町2-5-2"}
        },
        
      "baseSalary": {
          "@type": "MonetaryAmount",
          "currency": "JPY",
          "value": {
            "@type": "QuantitativeValue",
            "value": 1050, 
      "minValue":1050,                                          
            "maxValue":1500,                                         
            "unitText": "HOUR"
          }
        }
      }
        "workHours": "9:00～18:00(自由出勤制)</p>】" ,`
    }
  }, {
    path: '/jimu/',
    name: 'officeWork',
    component: OfficeWork,
    meta: {
      title: '事務(勤務時間自由・岐阜市)のパート・アルバイトの求人・採用',
      description: '岐阜市にある株式会社グレイトヘルプの一般事務のパート・アルバイトの求人・採用情報です。出勤曜日と勤務時間が自由で働きやすい職場です。時短勤務・扶養の範囲内での勤務ももちろんOKです。マニュアルにそった定例業務が中心のデスクワークです。責任感があり、正確な業務遂行ができる方のご応募をお待ちしています。',
      gfjScriptContent: `{
        "@context" : "http://schema.org/",
        "@type" : "JobPosting",
        "title" : "事務（経験者）",
        "description" : 
      "<p>■仕事内容<br>
      お茶出しや掃除などの雑務的な業務もありますが、業務の多くは、マーケティング部門やプログラミング部門のサポート業務です。そのため、パコソンの操作スキルは必須です。具体的には、ブラインドタッチでタイピングができること、ExcelやWordの操作ができることは必須で、業務の大半がパソコンと向き合う仕事になります。
      </p>
      <p>■応募資格<br>
      入社の段階で、事務職の経験者相当のパソコンスキルは必須です。具体的には、ブラインドタッチでのタイピング、ExcelやWordなどの操作は必須です。ウェブのマーケティングやプログラムなどに関する知識は入社の段階で一切必要ではありません。</p>
      <p>■勤務時間<br>
      平日9時半～17時の時間帯で、ご自由に調整していただくことができます。<br>
      ※1週間あたり15時間以上勤務することが条件です。</p>
      <p>■グレイトヘルプはこんな職場です<br>
      グレイトヘルプは、パートタイム勤務の従業員が働きやすい職場環境づくりに努めています。自由シフト出勤、無駄な拘束時間なし、ドリンクや軽食の支給など、働きやすさが自慢です。自由シフト出勤は、家事や育児、プライベートとの両立が可能で、もっとも好評な制度となっています。スキルや経験はあるけど、労働時間の問題で良い仕事が見つからないという方にピッタリな職場です。</p>
      
      <p>◎自由シフト出勤、無駄な拘束時間なし<br>
      ◎ドリンク、軽食の支給<br>
      ◎有給制度(パートタイムの方にも有給を支給しています)<br>
      ◎常備薬完備(頭痛薬や風邪薬など)<br>
      ◎パソコンは全てブルーライトカット<br>
      ◎服装、髪型、ネイル自由<br>
      ◎書籍や教材が充実<br>
      ◎男女トイレ別<br>
      ◎ペーパータオルで衛生的</p>
      ",
      
      
    "identifier": {
    "@type": "PropertyValue",
    "name": "株式会社グレイトヘルプ",
    "value": "08"
    },
      
    "datePosted" : "2023-02-15",
      
    "validThrough" : "2024-05-22",
      
    "employmentType" : "PART_TIME",
      
    "hiringOrganization":{
        "@type":"Organization",
        "name":"株式会社グレイトヘルプ",
        "sameAs":"https://greathelp.co.jp",
        "logo":" https://greathelp.co.jp/wp202007/wp-content/themes/gh/img/GH-logo-mark.png"
        },
      
      
      "jobLocation":{
        "@type":"Place",
        "address":{
        "@type":"PostalAddress",
        "addressCountry":"JP",
          "postalCode":"500-8468",
          "addressRegion":"岐阜県",
          "addressLocality":"岐阜市",
          "streetAddress":"加納桜田町2-5-2"}
        },
        
      "baseSalary": {
          "@type": "MonetaryAmount",
          "currency": "JPY",
          "value": {
            "@type": "QuantitativeValue",
            "value": 1050, 
      "minValue":1050,                                          
            "maxValue":1500,                                         
            "unitText": "HOUR"
          }
        }
      }
        "workHours": "9:00～18:00(自由出勤制)</p>】" ,`
    }
  },
  // {
  //   path: '/contact',
  //   name: 'contact',
  //   component: Contact,
  //   meta: {
  //     title: 'お問い合わせフォーム 株式会社グレイトヘルプ パート・アルバイト求人サイト',
  //     description: '※後から記入※後から記入※後から記入※後から記入'
  //   }
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition == true) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach((to, from, next) => {
  let documentHead = document.getElementsByTagName('head');
  let gfjScript = document.createElement('script');
  gfjScript.type = 'application/ld+json';
  gfjScript.innerHTML = to.meta.gfjScriptContent;
  documentHead[0].appendChild(gfjScript);
  //↑gfj用
  setTitle(to.meta.title);
  setDescription(to.meta.description);
  store.dispatch("setRootUrl");

  if (to.path.slice(-1) === "/") {
    next();
  } else {
    next(to.path + "/");
  }
});

export default router
