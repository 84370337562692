<template>
  <div class="home-our-business2">
    <UiHeading
      :h="'2'"
      :type="'A'"
      :text="'<span style=line-height:1.1;>Our Colleague &<br>Atmosphere</span>'"
      :subTitle="'どんな人が働いているの？職場の雰囲気は？'"
    />

    <div class="wrap huniki">
      <p>
        年齢層は20代から50代まで幅広いです。女性は主婦、男性はフリーランスの傍らで勤務される方が活躍しています。男女比はほぼ半々です。
      </p>
      <p>
        社内には、優しくて、周りへの気遣いができる人のみがいます（会社の雰囲気に合わない人は採用していません）。入社後も、先輩として、丁寧に教育・フォローをしてくれる優しい人ばかりです。
      </p>
      <p>
        社内規則を遵守し、周りのメンバーと協調性を持ち、担当業務をしっかりと遂行していれば、社内で厳しい言葉で注意を受けることや、険悪の雰囲気になることなどが全くなく、とっても平和な雰囲気で働きやすい職場になっています。
      </p>
      <!-- <img src="@/assets/ezgif.com-gif-maker-F.png" alt="働いている人イメージ" class="illust-a pc-only">
      <img src="@/assets/ezgif.com-gif-maker-H.png" alt="働いている人イメージ" class="illust-a sp-only" > -->
      <div class="white-back">
        <div class="left">
          <img src="@/assets/hito001.webp" alt="Tさん" class="icon" />
          <p class="name-age">Tさん（40代女性）</p>
          <p class="job">入社1年目 デザイナー</p>
        </div>
        <div class="right">
          <h4 class="title">
            お金も時間も必要な自分にピッタリな職場。もっと早く入社したかったです。
          </h4>
          <p class="text">
            3人の子供がいて、これから大学・高校に進学になります。お金も時間も必要な中、入社時に取り決めた勤務時間の中で、自由に出勤ができて、無駄な拘束時間がなく働けてありがたいです。始業時間も自由で、出勤予定時刻に対して遅刻もOKなので、自分のペースで朝の支度ができるのもありがたいです。従業員が使う設備や福利厚生などに会社がしっかり投資してくれるし、仕事の指示も明確に出してくれるので、仕事もしやすいです。もっと早く入社したかったです。
          </p>
        </div>
      </div>
      <div class="white-back">
        <div class="left">
          <img src="@/assets/hito004.webp" alt="Tさん" class="icon" />
          <p class="name-age">Yさん（30代男性）</p>
          <p class="job">入社2年目 プログラマー</p>
        </div>
        <div class="right">
          <h4 class="title">
            フリーランスの傍らで勤務できて、しかも時間も自由で助かっています
          </h4>
          <p class="text">
            夫婦で共働きで、自分が主夫をしながら、フリーランスのプログラマーとして活動しています。フリーランスで活動しながら、グレイトヘルプにも勤務してします。そのおかげで仕事・給与も安定して、助かっています。勤務時間も自由なので、家庭と両立もしやすいです。自分のスキルを活かして、高時給で効率良く仕事をして収入が得られるのもありがたいです。職場の雰囲気もとても良いですね（キレる人、怖い人がいません笑）。社風として、自由の範囲が広くて働きやすい職場環境だからこそ、その自由の範囲を守って、仕事で貢献できる人にとってすごく働きやすい職場です。
          </p>
        </div>
      </div>
      <div class="white-back">
        <div class="left">
          <img src="@/assets/hito002.webp" alt="Oさん" class="icon" />
          <p class="name-age">Oさん（50代女性）</p>
          <p class="job">入社6年目 デザイナー</p>
        </div>
        <div class="right">
          <h4 class="title">
            勤務時間の融通が効くし、スキルを活かして効率的に働けます
          </h4>
          <p class="text">
            勤務時間が自由な上、家庭の事情による急なお休みをも取りやすく、助かっています。結婚・出産前に、デザイナーとして働いた経験とスキルを活かして、扶養の範囲内の勤務で、効率的に働けるのも会社の魅力です。ウェブデザインの経験がない状態で入社しましたが、ウェブのデザインのルールについても身につけることができ、また、いろいろな業種・商品・サービスのお仕事があって、楽しみながら日々お仕事ができています。社風として自由の範囲が広いのですが、そんな中、働くみんながしっかりしていて、協調性が高くて、チームワークが良いのも会社の良いところです。
          </p>
        </div>
      </div>
      <p class="kome">※インタビュー当時の情報です。実在する従業員の声です。パート勤務者のため、顔出しはしておりません。</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "../Ui/Heading.vue";
export default {
  name: "HomeOurBusiness2",
  components: { UiHeading },
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.home-our-business2 {
  background-image: url(~@/assets/GH-kyujin-top-back004.webp);
  background-size: cover;
  padding: 100px 0 200px;
  @media screen and(max-width: 767px){
    padding-bottom: 100px;
  }
  p {
    margin-bottom: 20px;
  }
  .huniki {
    padding: 70px 38px 0;
  }
  img {
    vertical-align: top;
  }
}
/deep/.sub-title {
  padding-top: 23px;
}
.white-back {
  background-color: white;
  padding: 40px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 70px;
  @media screen and(max-width: 767px){
    flex-direction: column;
  }
  .left {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and(max-width: 767px){
      width: 100%;
    }
    .icon {
      width: 80%;
      margin-bottom: 15px;
    }
    .job,
    .name-age {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 1.5em;
    }
  }
  .right {
    width: 78%;
    padding-right: 20px;
    @media screen and(max-width: 767px){
      width: 100%;
      padding-right: 0;
    }
    h4 {
      font-size: 22px;
      margin-bottom: 10px;
      color: var(--sub);
      @media screen and(max-width: 767px){
        margin-top: 10px;
      }
    }
    .text{
      text-align:justify;
    }
  }
}
.kome{
  font-size: 14px;
  text-align: right;
  @media screen and(max-width: 767px){
    text-align: left;
  line-height: 1.5em;
  }
}
</style>
