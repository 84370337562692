<template>
  <div id="app">
    <Header class="header" :path="path" />
    <router-view class="body-top" />
    <ToTopButton :path="path" />
    <Footer :path="path" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import "reset-css";
import Header from "@/components/Layout/Header.vue";
import ToTopButton from "@/components/Ui/ToTopButton.vue";
import Footer from "@/components/Layout/Footer.vue";

export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    Header,
    Footer,
    ToTopButton,
  },
  computed: {
    ...mapGetters(["rootUrl"]),
    path() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");

#app {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 18px;
  color: #3e3a39;
  line-height: 2em;
  @media screen and (max-width: 767px) {
    line-height: 1.6em;
  }
}

:root {
  --main: #171c61;
  --sub: #35a7db;
  --sub2: #3e3a39;
  --black: #3e3a39;
}

html * {
  box-sizing: border-box !important;
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

.wrap {
  width: 100vw; //画面幅に対して、基本100%
  max-width: 1150px; //でも、1150pxを超える画面幅でwidth: 100%では困るのでこの設定
  // width: 1150px;
  margin: 0 auto;

  @media screen and (max-width: 1150px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.content-wrap {
  max-width: 934px;
  margin: 0 auto;
  @media screen and (max-width: 1150px) {
    // padding-left: 20px;
    // padding-right: 20px;
  }
}

.body-top {
  margin-top: 70px;
  @media screen and (max-width: 767px) {
    margin-top: 70px!important;
  }
}
.pc-only {
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.pc-only-1150 {
  @media screen and (max-width: 1150px) {
    display: none;
  }
}

.tb-only {
  display: none;
  @media screen and (min-width: 768px) and (max-width: 1150px) {
    display: initial !important;
  }
}
.sp-only {
  display: none;
  @media screen and (max-width: 767px) {
    display: initial !important;
  }
}
//職種別タイトル用
.job-category-title {
  font-size: 65px;
  font-weight: 500;
  margin-bottom: 20px;
  @media screen and (max-width: 767px) {
    font-size: 32px;
  }
}
.job-category-sub {
  margin-top: 20px;
  font-size: 18px;
}
//ここまで
h1,
h2 {
  font-size: 65px;
  font-weight: 500;
  color: var(--sub2);
}
h3 {
  font-size: 36px;
  @media screen and (max-width: 767px) {
    font-size: 26px;
  }
}
h4 {
  font-size: 28px;
}
//ヘッダー用のスペースレンジャー
.space-top-70 {
  padding-top: 70px;
  @media screen and (max-width: 767px) {
    padding-top: 60px;
  }
}
//共通スペースレンジャー
.space-btm-200 {
  margin-bottom: 200px;
  @media screen and (max-width: 767px) {
    margin-bottom: 100px;
  }
}
.space-btm-200p {
  padding-bottom: 200px;
  @media screen and (max-width: 767px) {
    padding-bottom: 100px;
  }
}
.space-top-130 {
  margin-top: 130px;
  @media screen and (max-width: 767px) {
    margin-top: 60px;
  }
}
.space-top-150 {
  margin-top: 150px;
  @media screen and (max-width: 767px) {
    margin-top: 80px;
  }
}
.space-top-300 {
  margin-top: 300px;
  @media screen and (max-width: 767px) {
    margin-top: 150px;
  }
}
.space-btm-80 {
  margin-bottom: 80px;
}
</style>
