<template>
  <div class="home-message">
    <div class="blue-back">
      <div class="cover wrap">
        <div class="title" id="message">
          <h2>message</h2>
          <p>職場に関する思い・メッセージ</p>
        </div>
        <img
          alt="職場イメージ"
          src="@/assets/GH-kyujin-top-illustration009.webp"
          class="stuff"
        />
      </div>
    </div>

    <div>
      <div class="message-from wrap" @click="MessageShow = !MessageShow">
        <div class="img-cover">
          <img src="@/assets/GH-kyujin-top-ozeki.webp" alt="小関貴久" />
          <p class="company">
            株式会社 グレイトヘルプ
          </p>
          <p class="name">代表取締役 小関 貴久</p>
        </div>
        <div class="text-and-button">
          <div class="text-cover">
            <div>
              <p>
                弊社の求人サイトをご覧いただき、ありがとうございます。</p>
              <p>
                弊社は、家事や子育てに忙しい主婦・主夫、フリーランス・個人事業主としてご自身のお仕事をしながら勤務される方が主となって活躍する「仕事における時間的な自由」を大切にした職場です。
              </p>
              <p>
                私は28歳のときに起業しましたが、大学卒業から6年間は会社員として、非常に残業が多く、また、拘束時間の長い職場で働いていました。そして、以下のような生活をしてきました。
              </p>

            </div>
            <transition>
              <div v-if="MessageShow === true">

                <ul>
                  <li>
                    ・8時始業なのに7時30分までには会社にいないといけない暗黙のルール
                  </li>
                  <li>
                    ・昼食を20分で食べても、60分間の昼休憩があり、その分、帰る時間が遅くなる
                  </li>
                  <li>
                    ・仕事が終わると、同僚や先輩に話かけられて、30分、1時間と会話が続いて帰れない
                  </li>
                  <li>
                    ・金曜日の夜や土日なのに、会社の人に誘われて断れず、時間を失う
                  </li>
                  <li>
                    ・勤務時間が長すぎて、仕事に対して集中力がない、良い仕事ができない
                  </li>
                </ul>
                <p>
                  私は、このような職場で働くことは「人生の無駄」と痛感し、また、この職場環境では良い仕事はできないとも痛感し、「これと真逆な会社を作ろう」と思い、起業しました。そして、「無駄な拘束時間がなく、会社にいて仕事をしているのに給与が発生しないことがない職場」にしてきました。
                </p>
                <p>
                  創業初年度からこのような職場環境で従業員を雇用し、これまで10年以上、いろいろな人と仕事をしてきました。その中で、弊社と相性が良く、力を発揮し、かつ、職場環境に満足して定着してくださるのは、家事や子育てに忙しい主婦・主夫、フリーランス・個人事業主としてご自身のお仕事をしながら弊社にも勤務される方だとわかりました。実際に弊社では、このような人たちが中心となって活躍しています。
                </p>
                <p>
                  出勤曜日が自由、勤務時間が自由、無駄な拘束時間なしなど、仕事において時間的な制約がある人が働きやすい勤務条件と職場環境を整えています。あなたも弊社の職場環境が自分に合う、力を発揮できる職場だと感じたら、ぜひ求人にご応募してみてください。一緒に働けることを楽しみにしています。
                </p>

              </div>
            </transition>
          </div>
          <img
            v-if="MessageShow === true"
            src="@/assets/GH-kyujin-top-pa004.webp"
            class="more close"
            alt="closeボタン"
            v-scroll-to="`#message`"
          />
          <img
            v-else
            src="@/assets/GH-kyujin-top-pa003.webp"
            class="more open"
            alt="moreボタン"
          />
        </div>
      </div>
    </div>
    <div class="wrap show-block">
      <div class="content-wrap cover">
        <div class="sub-title">
          <h2>About us</h2>
          <p>どんな事業・仕事をしている会社なの？</p>
        </div>
        <img
          src="@/assets/ezgif.com-gif-maker-C.png"
          class="hatena"
          alt="疑問イメージ"
        />
        <p>
          株式会社グレイトヘルプは、企業や店舗、通販の集客・売上アップをお手伝いする事業をしています。具体的には、事業やマーケティングに関するコンサルティング、ホームページの制作、ECサイトの制作、ショッピングモールでの販売代行、インターネット広告、SNS運用、システム開発、紙媒体のデザインなど、ウェブ・インターネットでのマーケティングや集客や媒体制作が主の仕事内容です。
        </p>
        <p>
          弊社のように、ホームページの制作やインターネットのマーケティングを事業内容とする会社は世の中に多数あります。そんな中でも、弊社の特徴は、「売上アップや集客強化」に対するノウハウ・スキル・実績があることです。
        </p>
        <p>
          弊社は、創業から、「アフィリエイト」というインターネット上の完全成果報酬式の販売を事業として行っています。お客様の商品を販売すれば報酬を頂ける、販売できなければ1円も報酬を頂けないという「完全成果報酬」という形態での販売のため、ノウハウやスキルがなければ、事業としてできるものではありません。このような難易度が高い事業で弊社は日本でも有数の実績を上げてきました。
        </p>
        <p>
          弊社は、取引するお客様に対して「売上アップや集客強化」などの結果を提供することができます。実際に弊社でホームページを制作したお客様で、ホームページを解約して他社へ切り替えたケースはありません（※集客・マーケティング強化、または、求人強化のホームページ制作のご依頼での実績）。また、年商が3倍になったり、販売数で日本一を獲得したりとインパクトのある結果を出した案件も多数あります。このようにお客様に価値提供することができるのが弊社の特徴であり、弊社のお仕事です。
        </p>
        <p>
          そのため、お客様との関係は長期に渡り、また、結果を提供できるので、お客様に感謝されながらお仕事ができるのも弊社のお仕事のメリットです。そして、このような「売上アップや集客強化などのマーケティングの思考」も弊社のお仕事を通して学ぶことができます。
        </p>
        <p>
          職種によって従事する業務は異なりますが、ホームページの設計、ライティング、デザイン、コーディング、システムの設計、システムの開発、広告の運用、SNSの運用、紙媒体のデザインなどの業務を行っていただきます。そして、あなたのお仕事・作品が世の中に出回り、依頼してくださったお客様の事業の発展へとつながります。このようにお仕事を通してお客様の事業をより良くする、そんな価値提供をできるのが弊社のお仕事です。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HomeMessage",
  components: {},
  props: {
    path: String,
  },
  data() {
    return { MessageShow: false };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.blue-back {
  background-color: var(--sub);
  padding-top: 50px;
  .cover {
    min-height: 265px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
  .title {
    h2 {
      color: white;
      margin-bottom: 30px;
    }
    p {
      color: var(--main);
      @media screen and (max-width: 767px) {
        margin-bottom: 30px;
      }
    }
  }
  .stuff {
    width: 50%;
    margin-right: 100px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-right: 0;
      // width: calc(100% - 130px);
      // margin-right: 30px;
    }
  }
}
.message-from {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  // margin-top: -180px;
  margin-top: 50px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
  .img-cover {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 23%;
    margin-top: 10px;
    @media screen and (max-width: 767px) {
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      width: 60%;
    }
    img {
      width: 100%;
    }
    .company {
      font-size: 14px;
    }
  }
}
.text-and-button {
  display: flex;
  align-items: flex-end;
  width: 70%;
  @media screen and (max-width: 767px){
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .text-cover {
    width: 100%;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 30px;
    }
    ul {
      margin: 30px 0;
      li {
        margin-left: 1em;
        text-indent: -1em;
      }
    }
    p{
      margin-bottom: 20px;
    }
  }
  .more {
    width: 100px;
    margin-left: 10px;
    @media screen and (max-width: 767px) {
      margin-left: 0;
      margin-top: 10px;
    }
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
  .open {
    animation: poyo 2s linear 1s infinite forwards;
  }
  
  @keyframes poyo {
    from,
    to {
      transform: none;
    }
    0% {
      transform: scale(1);
    }
    30% {
      transform: scale(1);
    }
    40% {
      transform: scale(1.1);
    }
    50% {
      transform: scale(1);
    }
    60% {
      transform: scale(1.1);
    }
    70% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
}

.show-block {
  .cover {
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    @media screen and (max-width: 767px) {
      margin-top: 100px;
    }
    .sub-title {
      text-align: center;
      h2 {
        margin-bottom: 30px;
      }
      p {
        color: var(--main);
      }
    }
    .hatena {
      width: 130px;
      position: absolute;
      top: 7%;
      right: 20%;
      @media screen and (min-width: 768px) and (max-width: 934px) {
        right: 100px;
      }
      @media screen and (max-width: 767px) {
        width: 100px;
        top: 0.9%;
        right: 37%;
      }
    }
    p {
      margin-bottom: 30px;
    }
  }
}
.v-enter {
  opacity: 0;
}
.v-enter-active {
  transition: opacity 2s;
}
.v-enter-to {
  opacity: 1;
}
.v-leave {
  opacity: 2;
}
.v-leave-active {
  transition: opacity 0.5s;
}
.v-leave-to {
  opacity: 0;
}

</style>
