<template>
  <div class="home-recommendation">
    <h3>こんな人はグレイトヘルプの<br class="sp-only">お仕事がおすすめ</h3>

    <UiSliderBox
      :images="images"
      :swiperOption="swiperOption"
      :height="100"
      :width="100"
      :button="false"
      class="slider"
    />
    <div class="wrap">
      <p>
        あなたがこのような希望をお持ちでしたら、引き続き株式会社グレイトヘルプの求人情報をご確認ください！<br />あなたのライフスタイル・ライフステージにピッタリなお仕事・職場である可能性がとっても高いです！
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiSliderBox from "@/components/Ui/SliderBox.vue";
export default {
  name: "HomeRecommendation",
  components: { UiSliderBox },
  props: {
    path: String,
  },
  data() {
    return {
      images: [
        { id: 1, imgpath: "GH-kyujin-top-osusume001.webp", name: "扶養の範囲内で働きたい" },
        { id: 2, imgpath: "GH-kyujin-top-osusume002.webp", name: "空いてる時間での時短勤務を希望" },
        { id: 3, imgpath: "GH-kyujin-top-osusume003.webp", name: "勤務曜日と勤務時間の自由がほしい" },
        { id: 4, imgpath: "GH-kyujin-top-osusume004.webp", name: "家庭・家族・プライベートが優先" },
        { id: 5, imgpath: "GH-kyujin-top-osusume005.webp", name: "子供の体調不良のときに急な休みが取れる" },
        { id: 6, imgpath: "GH-kyujin-top-osusume006.webp", name: "会社での無駄な拘束時間＜家庭・家族との時間" },
        { id: 7, imgpath: "GH-kyujin-top-osusume007.webp", name: "デスクワークがしたい" },
        { id: 8, imgpath: "GH-kyujin-top-osusume008.webp", name: "キャリアやスキルが活きる仕事がしたい" },
        { id: 9, imgpath: "GH-kyujin-top-osusume009.webp", name: "短時間✕高時給で効率よく働きたい" },
        { id: 10, imgpath: "GH-kyujin-top-osusume010.webp", name: "昇給があり、長く働ける職場を見つけたい" },
        { id: 11, imgpath: "GH-kyujin-top-osusume014.webp", name: "フリーランスの仕事の傍らで勤務したい" },
      ],
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 30,
        speed: 750,
        effect: "slide",
        loop: true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        //ナビゲーション設定
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1200: {
          slidesPerView: 2,
        },
        600: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
    created() {
    if  (window.matchMedia("(min-width: 1150px)").matches) {
      // default
      // alert(1);
    } else if (window.matchMedia("(min-width: 767px)").matches) {
      // tablet
      // alert(2);
      this.swiperOption.slidesPerView = "3";
      this.swiperOption.spaceBetween = 20;
    }else {
      // sp
      // alert(0);
      this.swiperOption.direction = "horizontal";
      this.swiperOption.slidesPerView = "1.3";
      this.swiperOption.speed = 800;
      this.swiperOption.spaceBetween = 20;
    }
  },
};
</script>

<style lang="scss" scoped>
.home-recommendation {
  padding: 100px 0;
  color: white;
  text-align: center;
  .slider {
    padding: 50px 0;
  }
  p {
    text-align: left;
  }
}
</style>
