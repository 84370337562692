<template>
  <div class="home-contact">
    <div class="blue-title">
      <div class="title-cover">
        <div class="title">
          <h2>求人へのご応募・お問い合わせ</h2>
          <img
            alt="スマホ"
            src="@/assets/ezgif.com-gif-maker-E.png"
            class="imge"
          />
        </div>
      </div>
    </div>

    <div class="wrap">
      <div class="white-title" id="mailto"><p>メールでのお問い合わせ</p></div>
      <!-- <div class="guide">
        <p>以下のフォームに必要事項を記入して、送信をお願いします。</p>
      </div> -->
      <HomeContactContactForm
        class="space-btm-80"
        style="background-color: #f2f2f2"
      />

      <div class="white-title"><p>お電話でのお問い合わせ</p></div>
      <div class="guide">
        <a href="tel:0582149005"><p class="tel">058-214-9005</p></a>
        <p class="small">（受付時間:平日9時 ～ 18時）</p>
        <p class="info">お電話でのお問い合わせの際は、050番号以外の電話番号から発信をお願いいたします。<br class="pc-only">050番号からの営業電話に迷惑をしているため、050番号からの着信には応答いたしません。</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UiHeading from "@/components/Ui/Heading.vue";
import HomeContactContactForm from "@/components/Home/Contact/ContactForm.vue";

export default {
  name: "HomeAboutUs",
  components: {
    // UiHeading,
    HomeContactContactForm,
  },
  props: {},
  data() {
    return {
      pageType: 0,
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.home-contact {

  .blue-title {
    background-color: var(--main);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    margin-bottom: 100px;
    .title-cover{
      .title{
        display: flex;
        align-items: center;
        @media screen and (max-width: 767px) {
          flex-direction: column-reverse;
          padding-bottom: 125px;
        }
        h2{
          color: white;
          font-size: 36px;
          @media screen and (max-width: 767px) {
            font-size: 24px;
          }
        }
        .imge{
          width: 115px;
          margin-left: 15px;
        }
      }
    }
  }
  .white-title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    border: 2px solid var(--main);
    margin-bottom: 50px;
    p {
      font-size: 32px;
      color: var(--main);
      @media screen and (max-width: 767px) {
        font-size: 26px;
      }
    }
  }

  .guide {
    text-align: center;
    color: var(--main);
    margin-top: 20px;
    margin-bottom: 200px;
    @media screen and (max-width: 767px) {
    margin-bottom: 100px;
  }

    .head {
      @media screen and (max-width: 767px) {
        padding-bottom: 30px;
      }
    }

    a {
      text-decoration: none;
      color: var(--main) !important;
    }
    .tel {
      font-size: 60px;
      font-weight: bold;
      margin-top: 30px;
      color: var(--main) !important;
      @media screen and (max-width: 767px) {
        font-size: 45px;
      }
    }
    .small {
      font-size: 22px;
      margin-top: 20px;
    }

    .info {
      margin-top: 20px;
    }

    @media (min-width: 751px) {
      a[href*="tel:"] {
        pointer-events: none;
        cursor: default;
      }
    }
  }
  .bottom {
    height: 200px;
    padding-top: 100px;
    @media screen and (max-width: 767px) {
      padding-top: 50px;
    }
    .container {
      width: 100%;
      img {
        vertical-align: bottom;
        width: 100%;
      }
    }
  }
}
</style>