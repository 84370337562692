<template>
  <div class="home-work-environment2">
    <div
      class="parallax_content1"
      v-prlx.mobile.background="{
        direction: 'y',
        fromBottom: false,
        speed: 0.02,
      }"
    >
      <div class="wrap">
        <UiHeading :h="'4'" :type="'E2'" :text="'仕事の環境に関すること'" />

        <div class="box-cover">
          <UiBox1
            v-for="box in boxes"
            :key="box.id"
            :imgPath="box.imgPath"
            :subTitle="box.subTitle"
            :text="box.text"
            class="box"
          />
        </div>
      </div>
    </div>

    <div class="salary space-top-130">
      <div class="wrap">
        <UiHeading :h="'4'" :type="'E2'" :text="'給与に関すること'" />

        <div class="box-cover">
          <UiBox1
            v-for="box in boxes2"
            :key="box.id"
            :imgPath="box.imgPath"
            :subTitle="box.subTitle"
            :text="box.text"
            class="box"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "../Ui/Heading.vue";
import UiBox1 from "@/components/Ui/Box1.vue";
export default {
  name: "home-work-place",
  components: {
    UiHeading,
    UiBox1,
  },
  props: {
    path: String,
  },
  data() {
    return {
      boxes: [
        // {
        //   id: 1,
        //   imgPath: "GH-kyujin-top-019.webp",
        //   subTitle: "デスクが広い",
        //   text: "全従業員のデスクサイズが140cm×85cmで、通常よりも広くなっています（通常は120cm×60cm）。",
        // },
        {
          id: 1,
          imgPath: "GH-kyujin-top-020.webp",
          subTitle: "ハイスペックな機器",
          text: "動作の速いハイスペックな機器を揃えているので、快適にお仕事ができます。",
        },
        {
          id: 2,
          imgPath: "GH-kyujin-top-041.webp",
          subTitle: "広いデスクと<br>ダブルモニター",
          text: "通常よりも広いデスクとダブルモニターを全従業員に用意しています。",
        },
        {
          id: 3,
          imgPath: "GH-kyujin-top-022.webp",
          subTitle: "パソコンは全て<br>ブルーライトカット",
          text: "パソコンには全てブルーライトカットのフィルターを設置。目のケアも大切にしています。",
        },
        {
          id: 4,
          imgPath: "GH-kyujin-top-040.webp",
          subTitle: "モニターの高さ調整",
          text: "視線をまっすぐ維持して、猫背にならないように、モニターの高さ調整をしています。",
        },
        // {
        //   id: 5,
        //   imgPath: "GH-kyujin-top-023.webp",
        //   subTitle: "立ちデスクあり",
        //   text: "リフレッシュを目的に、立ちデスクで仕事がしたい、という人のために、社内には立ちデスクも用意しています。",
        // },
        // {
        //   id: 6,
        //   imgPath: "GH-kyujin-top-024.webp",
        //   subTitle: "バランスボールあり",
        //   text: "リフレッシュを目的に、イスではなく、バランスボールで仕事がしたい、という人のために、社内にはバランスボールを用意しています。",
        // },
                {
          id: 5,
          imgPath: "GH-kyujin-top-026.webp",
          subTitle: "一人一台で共有なし",
          text: "デスク、イス、文具、イヤホン、電卓、など、一人一台、専用で使用しています。",
        },
        {
          id: 6,
          imgPath: "GH-kyujin-top-025.webp",
          subTitle: "スリッパOK",
          text: "仕事中は、靴ではなく、スリッパもOKです。",
        },

        {
          id: 7,
          imgPath: "GH-kyujin-top-031.webp",
          subTitle: "書籍や教材が充実",
          text: "社内には書籍が1,000冊以上あり、レンタルできます。スキルアップできる環境が整っています。",
        },
        {
          id: 8,
          imgPath: "GH-kyujin-top-032.webp",
          subTitle: "書籍を<br class=tb-only>会社負担で購入",
          text: "担当業務に関する書籍は、リクエストがあれば、全額会社負担で購入しています。",
        },
        // {
        //   id: 11,
        //   imgPath: "GH-kyujin-top-033.webp",
        //   subTitle: "社長からの推薦図書",
        //   text: "希望があれば、2,000冊以上の書籍を読んできた社長から、あなたのスキルアップにおすすめの書籍を提案します。",
        // },
        // {
        //   id: 12,
        //   imgPath: "GH-kyujin-top-034.webp",
        //   subTitle: "一眼レフカメラの<br class=tb-only>レンタル",
        //   text: "社内には20万円以上する一眼レフカメラ（NIKON、SONY）が2台あり、レンタル可能です。",
        // },
      ],
      boxes2: [
        {
          id: 1,
          imgPath: "GH-kyujin-top-027.webp",
          subTitle: "昇給",
          text: "勤務評価と業績に応じて、パート勤務者は年間で20円～100円/時の昇給実績があります。",
        },
        {
          id: 2,
          imgPath: "GH-kyujin-top-028.webp",
          subTitle: "高時給で長く働ける",
          text: "毎年昇給を行うため、勤務年数が長いほど高時給になります。詳細は募集要項をご確認ください。",
        },
        {
          id: 3,
          imgPath: "GH-kyujin-top-029.webp",
          subTitle: "交通費の実費支給",
          text: "全従業員が車通勤となっていて、交通費を全額支給しています（遠方の場合は例外あり）。ガソリンの市場価格に合わせて、交通費を改定しています。",
        },
        {
          id: 4,
          imgPath: "GH-kyujin-top-044.webp",
          subTitle: "有給制度",
          text: "パートタイムの従業員にも、社内規定に基づいて、有給休暇を支給しています。",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.home-work-environment2 {
  .parallax_content1 {
    background-image: url(~@/assets/GH-kyujin-top-back005.webp);
    background-size: cover;
    margin-top: 50px;
    padding: 100px 0;
    @media screen and (max-width: 768px) {
      background-image: url(~@/assets/GH-kyujin-top-back005-2sp.webp);
      background-size: initial;
      padding-bottom: 1px;

    }
  }
  .box-cover {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
      display: block;
    }
    .box {
      width: 23%;
      margin-bottom: 50px;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 100px;
      }
    }
  }
}
</style>
