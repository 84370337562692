<template>
  <div class="home-work-place">
    <UiHeading
      :h="'2'"
      :type="'A'"
      :text="'Our Workplace'"
      :subTitle="'こんな勤務条件・職場環境です'"
    />
    <div
      class="parallax_content1"
      v-prlx.mobile.background="{
        direction: 'y',
        fromBottom: false,
        speed: 0.02,
      }"
    >
      <div class="wrap">
        <UiHeading :h="'4'" :type="'E2'" :text="'勤務に関すること'" />

        <div class="box-cover">
          <UiBox1
            v-for="box in boxes"
            :key="box.id"
            :imgPath="box.imgPath"
            :subTitle="box.subTitle"
            :text="box.text"
            class="box"
          />
        </div>

        <div class="bottom">
          <div class="text">
            <p>
              仕事における時間的な自由を大切に考え、勤務の自由度を高くし、無駄な拘束時間を可能な限りカットしています。「会社に要する時間は最小に、ご自身の時間を最大に」が弊社の方針です。
            </p>
            <p>
              社内のメンバーの仲はとても良いですが、プライベートとは完全に切り分けられています。そのため、出勤前にカフェに行く、仕事終わりに従業員同士で会話をするという文化もなければ、週末に会社の人に飲みや遊びに誘われ、時間を失うということもありません（「会社の人とプライベートで仲良くしたい、遊びたい」という人は合わない職場です）。
            </p>
            <p>
              働く誰もが仕事に集中し、プライベートに極力時間を使える環境となっています。
            </p>
          </div>
          <img
            src="@/assets/GH-kyujin-top-illustration011.webp"
            alt="説明イラスト"
          />
        </div>

        <div class="about-free">
          <p>※「勤務時間が自由」について</p>
          <p>
            他の従業員と業務の進捗への影響がないことを前提にした勤務時間の自由となります。入社時に、1週間あたりの勤務時間（週12時間～）を決定します。この勤務時間において、出勤曜日と勤務時間が自由という仕組みです。出勤予定日にお休みをとった場合は、原則として1週間以内に欠勤分の勤務時間を補填する勤務をしていただいています（欠勤の理由と業務状況によっては補填なしの場合もあり）。お子様の長期休暇時は、事前の申請と調整によって、勤務日数・勤務時間を通常よりも減らすことは可能です。
          </p>
        </div>
      </div>
    </div>
    <!-- パララックス調整用 -->
    <!-- <div style="height:350px;"></div> -->
    <!-- パララックス調整用 -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "../Ui/Heading.vue";
import UiBox1 from "@/components/Ui/Box1.vue";
export default {
  name: "home-work-place",
  components: {
    UiHeading,
    UiBox1,
  },
  props: {
    path: String,
  },
  data() {
    return {
      boxes: [
        {
          id: 1,
          imgPath: "GH-kyujin-top-001.webp",
          subTitle: "勤務時間が自由",
          text: "平日9時30分～17時の中でご希望の曜日・ご希望の時間にて勤務の調整をします。直前での勤務時間と勤務曜日の変更も可能です。",
        },
        {
          id: 2,
          imgPath: "GH-kyujin-top-003.webp",
          subTitle: "1分単位で給与を計上",
          text: "勤務時間を1分単位で給与に計上しています。会社にいて時給が発生しない、無駄な拘束時間はありません。",
        },
        {
          id: 3,
          imgPath: "GH-kyujin-top-004.webp",
          subTitle: "昼食の休憩なしもOK",
          text: "昼食の休憩なしで勤務可能です。おにぎり程度なら勤務中に食べてOK。お昼休憩の1時間の拘束時間もカットできます。",
        },
        {
          id: 4,
          imgPath: "GH-kyujin-top-002.webp",
          subTitle: "急な休みが<br class=tb-only>取りやすい",
          text: "ご自身・ご家族様の体調不良など、当日の朝の連絡でも休みが取れます。社内チャットで「休みます」と連絡するのみでOKです。",
        },
        {
          id: 5,
          imgPath: "GH-kyujin-top-042.webp",
          subTitle: "遅刻OK<br><span class=row-4>自宅を早く出る必要なし</span>",
          text: "タイムカードを打刻してから勤務開始となるので、会社所定の勤務開始時間に間に合わせるために、自宅を早く出る必要はありません。",
        },
        {
          id: 6,
          imgPath: "GH-kyujin-top-006.webp",
          subTitle: "最長1週間の<br>連続休暇がとれる",
          text: "事前に申請し、業務を調整することで、最長1週間の連続休暇をとることができます。",
        },
        {
          id: 7,
          imgPath: "GH-kyujin-top-007.webp",
          subTitle: "朝礼・定例MTGなし",
          text: "その時間に全従業員を集めることは、従業員の時間の制約となるため、朝礼や定例MTGなどはありません。",
        },
        {
          id: 8,
          imgPath: "GH-kyujin-top-043.webp",
          subTitle: "有給制度",
          text: "パートタイムの従業員にも、社内規定に基づいて、有給休暇を支給しています。",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
.home-work-place {
  .parallax_content1 {
    background-image: url(~@/assets/GH-kyujin-top-back001.webp);
    background-size: cover;
    margin-top: 50px;
    padding: 100px 0;
    @media screen and (max-width: 767px) {
      // background-image: url(~@/assets/GH-kyujin-top-back001sp.webp);
      background-size: initial;
    }
  }
  .box-cover {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
      display: block;
    }
    .box {
      width: 23%;
      margin-bottom: 50px;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 100px;
      }
    }
  }
  .about-free {
    p {
      font-size: 15px;
      line-height: 1.5;
      &:first-child {
        margin-bottom: 10px;
        margin-top: 50px;
      }
      // &:nth-child(2) {
      // }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1150px) {
      display: block;
    }
    .text {
      width: 80%;
      @media screen and (max-width: 1150px) {
        width: 100%;
      }
      p {
        margin-bottom: 20px;
      }
    }
    img {
      width: 15%;
      @media screen and (min-width: 768px) and (max-width: 1150px) {
        width: 200px;
        margin-left: calc(100% - 200px);
      }
      @media screen and (max-width: 767px) {
        width: 60%;
        margin-left: 40%;
      }
    }
  }
}
</style>
