var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-our-buisiness"},[_c('div',{directives:[{name:"prlx",rawName:"v-prlx.mobile.background",value:({
      direction: 'y',
      fromBottom: false,
      speed: 0.02,
    }),expression:"{\n      direction: 'y',\n      fromBottom: false,\n      speed: 0.02,\n    }",modifiers:{"mobile":true,"background":true}}],staticClass:"parallax_content1"},[_c('div',{staticClass:"wrap"},[_c('UiHeading',{staticClass:"title",attrs:{"h":'2',"type":'A',"text":'Work Policy',"subTitle":'仕事の方針'}}),_vm._m(0),_vm._m(1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('div',{staticClass:"text"},[_c('h5',[_vm._v("■仕事のスピードよりクオリティ重視")]),_c('p',[_vm._v(" 弊社の価値はお客様に「結果」を提供することです。お客様も「結果」を期待して弊社に仕事を依頼します。そのため、下請けのような扱いで「低コストで早く作って」というお仕事はほとんどなく、パートナーという立ち位置で、結果につながるクオリティが必要になるお仕事がメインです。「仕事に追われる」という感覚なくお仕事ができます。また、仕事のスタンスとして、「雑で早い」ではなく、「適切な時間をかけ、正確であること」を大切にしています。 ")]),_c('h5',[_vm._v("■上司からの詳しい指示とマニュアルがある")]),_c('p',[_vm._v(" 弊社の業務の多くはマニュアル化されています。また、上司からの詳しい指示があります。さらに、上司からのフォローやフィードバックも丁寧に行っています。パート勤務の方については、制作物を「独創的に作る」というよりも、「指示のとおりにしっかりと作る」という業務をしていただくことが主となります。 ")])]),_c('img',{staticClass:"stuff",attrs:{"src":require("@/assets/GH-kyujin-top-illustration012.webp"),"alt":"お仕事イメージ"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block"},[_c('img',{attrs:{"src":require("@/assets/GH-kyujin-top-illustration004.webp"),"alt":"お仕事イメージ"}}),_c('div',{staticClass:"text"},[_c('h5',[_vm._v("■事務所内で仕事に集中するデスクワーク")]),_c('p',[_vm._v(" パート勤務の方は、事務所内でのデスクワークのお仕事です。社内メンバーでの打ち合わせはありますが、クライアントとの打ち合わせなど外出は基本的になく、事務所内で仕事に集中していただきます。 ")]),_c('h5',[_vm._v("■学べる環境がある")]),_c('p',[_vm._v(" 社内には書籍が1,000冊以上あり、レンタル可能です。また、担当業務に関連する書籍は会社で購入します。会社として、売上アップや集客などのマーケティングに強いため、業務を通してこれらの知識も身につけることができます。ただ形として制作物を作るだけではなく、マーケティングの思考を持った技術者になることができます。 ")])])])
}]

export { render, staticRenderFns }