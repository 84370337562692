<template>
  <div class="heading">
    <div
      :class="{
        typeA: type === 'A',
        typeA2: type === 'A2',
        typeB: type === 'B',
        typeB2: type === 'B2',
        typeB3: type === 'B3',
        typeB4: type === 'B4',
        typeB5: type === 'B5',
        typeC: type === 'C',
        typeD: type === 'D',
        typeD2: type === 'D2',
        typeE: type === 'E',
        typeE2: type === 'E2',
        typeE3: type === 'E3',
      }"
    >
      <h1 v-if="h === '1'" v-html="text"></h1>
      <h2 v-else-if="h === '2'" v-html="text"></h2>
      <h3 v-else-if="h === '3'" v-html="text"></h3>
      <h4 v-else-if="h === '4'" v-html="text"></h4>
      <h5 v-else-if="h === '5'" v-html="text"></h5>

      <div v-if="subTitle !== ''" class="sub-title">
        <div v-if="type === 'E' || 'E2'" class="black-line"></div>
        <p>
          {{ subTitle }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "heading",
  components: {},
  props: {
    text: String,
    h: String,
    type: String,
    subTitle: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
};
</script>

<style lang="scss" scoped>
//paddingバーの下の幅は設定してね♡
h1,
h2 {
  font-size: 65px;
  @media screen and (max-width: 767px) {
    font-size: 40px;
    padding: 0 10px;
    line-height: 1.5em;
  }
}
h3 {
  font-size: 36px;
  @media screen and (max-width: 767px){
    font-size: 26px;
  }
}

.typeA {
  // color: var(--main);

  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: center;
  }
}
.typeA2 {
  
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: white;
    text-align: center;
  }
  .sub-title{
    color: white;
  }
}
.typeB,
.typeB2,
.typeB3,
.typeB4,
.typeB5{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90px;
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: white;
  }
}
.typeB {
  .sub-title {
    color: var(--sub);
    font-size: 18px;
  }
}
.typeB,
.typeB3 {
  //背景メインカラー
  background-color: var(--main);
}
.typeB2,.typeB4 {
  //背景サブカラー
  background-color: var(--sub);
}
.typeB3,.typeB4 {
 //SP：3行用
 height: 100px;
 @media screen and (max-width: 1024px) {
   height: 100%;
    h2 {
      font-size: 21px;
      padding: 15px 0;
    }
  }
}
.typeB5 {
  //枠メインカラー
border: 2px solid var(--main);
height: 80px;
@media screen and (max-width: 767px) {
  height: 60px;
}
 h1,
  h2,
  h3,
  h4,
  h5{
    color: var(--main);
  }
  h2{
    @media screen and (max-width: 767px) {
      font-size:24px;
    }
  }
}
.typeC {
  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: left;
    border-bottom: var(--main) solid 1px;
    padding-bottom: 10px;
    font-weight: 500;
    padding-left: 10px;
  }
  .sub-title {
    text-align: left;
    padding-left: 10px;
  }
}
.typeD,
.typeD2 {
  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
  }
}
.typeD {
  // color: var(--sub);
}
.typeD2 {
  color: var(--main);
}
.typeE {
  text-align: center;
  .black-line {
    border-bottom: solid 2px var(--black);
    margin: 20px auto;
    width: 78px;
  }
}
.sub-title {
  color: var(--main);
  text-align: center;
  padding-top: 30px;
  font-size: 18px;
}
.typeE2 {
  text-align: center;
  .black-line {
    border-bottom: solid 2px var(--black);
    margin: 20px auto;
    width: 78px;
  }
  .sub-title {
    // color: var(--sub2);
    // display: none;
    padding-top:0;
  }
}
.typeE3 {
  padding-bottom: 50px;
  @media screen and(max-width: 767px){
    padding-bottom: 20px;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: center;
    padding-bottom: 10px;
    color: var(--main);
    font-size: 36px;
    font-weight: 400;
    @media screen and (max-width: 767px) {
      font-size: 28px;
      padding-bottom: 0;
    }
  }
  .black-line {
    border-bottom: solid 1px var(--main);
    margin: 20px auto;
    width: 78px;
  }
  .sub-title {
    color: var(--sub2);
  }
}
p {
  margin: 0;
}
h2 {
  margin: 0;
}
</style>
