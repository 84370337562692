<template>
  <div class="home-selection-schedule">
    <div class="wrap">
      <UiHeading
        :h="'2'"
        :type="'A'"
        :text="'Selection Flow'"
        :subTitle="'ご応募・選考の流れ'"
      />
      <div class="content-wrap">
      <img
        alt="猫"
        src="@/assets/GH-kyujin-top-illustration010.webp"
        class="imge01"
      />
      <img
        alt="標識"
        src="@/assets/GH-kyujin-top-osusume011.webp"
        class="imge02"
      />
        <div class="step-cover" v-for="step in steps" :key="step.id">
          <div class="step">
            <p class="no">{{ step.no }}</p>
            <p class="title">{{ step.title }}</p>
          </div>
          <p v-html="step.text" class="text"></p>
        </div>
      </div>
    </div>
    <UiSliderBox
      :images="images"
      :swiperOption="swiperOption"
      :height="100"
      :width="100"
      :button="false"
      class="slider"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "../Ui/Heading.vue";
import UiSliderBox from "@/components/Ui/SliderBox.vue";
export default {
  name: "HomeMessage",
  components: {
    UiHeading,
    UiSliderBox,
  },
  props: {
    path: String,
  },
  data() {
    return {
      steps: [
        {
          id: 1,
          no: "01",
          title: "応募のご連絡",
          text: "当サイトのお問い合わせフォーム（推奨）、または、お電話にて、求人に応募したい旨を弊社までご連絡ください。選考書類の送付についてご案内をいたします。",
        },
        {
          id: 2,
          no: "02",
          title: "選考書類の送付",
          text: "弊社が指定する選考書類を弊社までお送りください。一次試験は書類選考となります。なお、不合格の場合、選考書類の返送はせず、弊社にて破棄します。",
        },
        {
          id: 3,
          no: "03",
          title: "面接のご案内",
          text: "書類選考の通過者には面接を案内いたします。",
        },
        {
          id: 4,
          no: "04",
          title: "面接",
          text: "弊社の事務所にて、面接とお仕事に対するアンケートを行います。場合によっては、実技確認をすること、面接を複数回実施することがあります。面接時の服装は自由です。",
        },
        {
          id: 5,
          no: "05",
          title: "合否の連絡",
          text: "合否の連絡をします。合格の場合は、勤務開始日を決定します。",
        },
        {
          id: 6,
          no: "06",
          title: "勤務開始",
          text: "決定した勤務開始日から勤務となります。",
        },
      ],
      images: [
        // { id: 1, imgpath: "GH-kyujin-top-007.webp", name: "会社イメージ1" },
        { id: 1, imgpath: "slider/01.webp", name: "会社イメージ1" },
        { id: 2, imgpath: "slider/02.webp", name: "会社イメージ2" },
        { id: 3, imgpath: "slider/03.webp", name: "会社イメージ3" },
        { id: 4, imgpath: "slider/04.webp", name: "会社イメージ4" },
        { id: 5, imgpath: "slider/05.webp", name: "会社イメージ5" },
        { id: 6, imgpath: "slider/06.webp", name: "会社イメージ6" },
        { id: 7, imgpath: "slider/07.webp", name: "会社イメージ7" },
        { id: 8, imgpath: "slider/08.webp", name: "会社イメージ8" },
        { id: 9, imgpath: "slider/09.webp", name: "会社イメージ9" },
      ],
      swiperOption: {
        slidesPerView: 6,
        spaceBetween: 70,
        speed: 1000,
        effect: "slide",
        loop: true,
        centeredSlides : true,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        //ナビゲーション設定
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1200: {
          slidesPerView: 2,
        },
        600: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
    created() {
    if  (window.matchMedia("(min-width: 1150px)").matches) {
      // default
      // alert(1);
    } else if (window.matchMedia("(min-width: 767px)").matches) {
      // tablet
      // alert(2);
      this.swiperOption.slidesPerView = "4";
      this.swiperOption.spaceBetween = 20;
    }else {
      // sp
      // alert(0);
      this.swiperOption.direction = "horizontal";
      this.swiperOption.slidesPerView = "1.5";
      this.swiperOption.speed = 800;
      this.swiperOption.spaceBetween = 20;
    }
  },
};
</script>

<style lang="scss" scoped>
.imge01 {
  position: absolute;
  top: -5.5%;
  right: 15%;
  width: 100px;
  @media screen and (max-width: 767px){
    width: 80px;
    top: -3.8%;
  }
}
.imge02 {
  width: 100px;
  position: absolute;
  top: -13.4%;
  right: 7%;
  @media screen and (max-width: 767px){
    top: -11%;
    right: -4%;
  }
}
.content-wrap {
  position: relative;
  margin-top: 100px;
}
.step-cover {
  margin-bottom: 50px;
  .step {
    background-color: var(--sub);
    color: white;
    display: flex;
    padding: 20px 0;
    .no {
      width: 100px;
      font-size: 27px;
      text-align: center;
      border-right: white 1px solid;
      @media screen and (max-width: 767px){
        width: 60px;
      }
    }
    .title {
      padding-left: 50px;
      font-size: 22px;
      @media screen and (max-width: 767px){
        padding-left: 20px;
      }
    }
  }
  .text {
    margin-top: 10px;
  }
}
</style>
