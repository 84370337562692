<template>
  <div class="ui-table4">
    <div class="table-inner">
      <table>
        <tr>
          <th>雇用形態</th>
          <td>パートタイム・アルバイト（雇用期間の定めなし）</td>
        </tr>
        <tr>
          <th>勤務場所</th>
          <td>岐阜市加納桜田町2-5-2（転勤なし）</td>
        </tr>
        <tr>
          <th>勤務時間</th>
          <td>
            平日9時30分～17時のご希望の曜日・時間帯で週12時間以上（※月間120時間未満）
          </td>
        </tr>
        <tr v-for="table in tableList" :key="table.id">
          <th>
            {{ table.th }}
          </th>
          <td v-html="table.td"></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UiTable4",
  components: {},
  props: {
    tableList: Array,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  padding: 0 83px;
  border-collapse: collapse;
  tr {
    th,
    td {
      border: 1px var(--black) solid;
      padding: 10px 0;
    }
    th {
      text-align: center;
      width: 15%;
      background-color: rgb(226, 226, 226);
      vertical-align: middle;
    }
    td {
      text-align: left;
      width: 85%;
      padding: 10px 20px;
            @media screen and (max-width: 767px) {
        padding-left: 10px;
      }
    }
  }
  // tr:last-child{
  //   border-bottom: solid 1px var(--black);
  //    @media screen and (max-width: 767px){
  //      border: none;
  //    }
  // }
}
</style>