<template>
  <div class="home-open-position">
    <UiHeading
      :h="'2'"
      :type="'A'"
      :text="'Open Position'"
      :subTitle="'募集中の職種'"
    />
    <div class="wrap">
      <div class="position-cover">
        <p>プログラマー（経験者）</p>
        <UiTable :tableList="tableList1" />
        <p class="detail">プログラマー職は、入社後の勤務評価（保有スキル、業務対応力、勤務態度）によって、在宅勤務を部分的に許可しています。実績として入社後1年で在宅勤務の許可を得た従業員がいます。</p>
        <div class="detail-button">
          <router-link to="/pg">詳細を見る</router-link>
        </div>
      </div>
      <div class="position-cover">
        <p>プログラマー（未経験者～経験3年未満）</p>
        <UiTable :tableList="tableList2" />
        <p class="detail">※当職は、「講座や書籍などでプログラミング（HTML・CSS・PHP・Javascriptなど）の勉強をしていて、スキルはあるが実務経験がない人」「プログラミングの実務経験が3年未満でプログラミングのスキルがある人」が対象です。</p>
        <div class="detail-button">
          <router-link to="/pg01">詳細を見る</router-link>
        </div>
      </div>
      <div class="position-cover">
        <p>ウェブサイト制作（経験者）</p>
        <UiTable :tableList="tableList3" />
        <div class="detail-button">
          <router-link to="/web">詳細を見る</router-link>
        </div>
      </div>
      <div class="position-cover">
        <p>ウェブサイト制作（未経験者～経験2年未満）</p>
        <UiTable :tableList="tableList4" />
          <p class="detail">※当職は、「講座や書籍などでウェブサイト制作（HTML/CSS）の勉強をしていて、スキルはあるが実務経験がない人」「ウェブサイト制作の実務経験が2年未満でウェブサイト制作のスキルがある人」が対象です。</p>
        <div class="detail-button">
          <router-link to="/web01">詳細を見る</router-link>
        </div>
      </div>
      <div class="position-cover">
        <p>ウェブデザイナー（経験者）</p>
        <UiTable :tableList="tableList5" />
        <div class="detail-button">
          <router-link to="/web-design">詳細を見る</router-link>
        </div>
      </div>
      <div class="position-cover">
        <p>デザイナー（経験者）</p>
        <UiTable :tableList="tableList6" />
        <div class="detail-button">
          <router-link to="/design">詳細を見る</router-link>
        </div>
        <div class="position-cover">
          <p>マーケティングサポート（未経験OK）</p>
          <UiTable :tableList="tableList7" />
          <p class="detail">マーケティングサポートは実務経験不問で未経験者の応募が可能です。業務は先輩従業員から詳細に指示をしますので、それを実行する能力とパソコンスキルが必要となる業務です。パソコンを使った仕事に慣れていて、Excel（資料作成、調査結果のまとめが主。関数やグラフのスキルは不問）やWordを日常的に使用した実務経験があれば応募可能です。</p>
          <div class="detail-button">
            <router-link to="/marketing">詳細を見る</router-link>
          </div>
        </div>
        <div class="position-cover">
          <p>広告運用サポート（未経験OK）</p>
          <UiTable :tableList="tableList8" />
            <p class="detail">広告運用サポートは実務経験不問で未経験者の応募が可能です。業務は先輩従業員から詳細に指示をしますので、それを実行する能力とパソコンスキルが必要となる業務です。パソコンを使った仕事に慣れていて、Excel（資料作成、調査結果のまとめが主。関数やグラフのスキルは不問）やWordを日常的に使用した実務経験があれば応募可能です。</p>
          <div class="detail-button">
            <router-link to="/ad">詳細を見る</router-link>
          </div>
        </div>
        <div class="position-cover">
          <p>経理（経験者）</p>
          <UiTable :tableList="tableList9" />
          <div class="detail-button">
            <router-link to="/keiri">詳細を見る</router-link>
          </div>
        </div>
        <div class="position-cover">
          <p>事務（経験者）</p>
          <UiTable :tableList="tableList10" />
          <div class="detail-button">
            <router-link to="/jimu">詳細を見る</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "../Ui/Heading.vue";
import UiTable from "../Ui/Table.vue";
export default {
  name: "HomeOPenPosition",
  components: {
    UiHeading,
    UiTable,
  },
  props: {
    path: String,
  },
  data() {
    return {
      tableList1: [
        {
          id: 1,
          th: "時間給",
          td: "1,400円～1,800円 <br>※「長期間、勤務していただきたい」という思いから、スタートの時給は1,400円で、勤務評価と勤務年数によって時給が上がる評価制度をとっています。",
        },
        {
          id: 2,
          th: "業務内容",
          td: "HTML・CSS・PHP・Javascriptなどによるウェブプログラミング業務など",
        },
      ],
      tableList2: [
        {
          id: 1,
          th: "時間給",
          td: "1,050円～1,250円 <br>※基本的に時給は1,050円で、入社時の保有スキルと実務経験によって優遇する場合があります。",
        },
        {
          id: 2,
          th: "業務内容",
          td: "HTML・CSS・PHP・Javascriptなどによるウェブプログラミング業務、会社から指示がある一切の業務",
        },
      ],
      tableList3: [
        {
          id: 1,
          th: "時間給",
          td: "1,200円～1,600円<br>※「長期間、勤務していただきたい」という思いから、スタートの時給は1,200円で、勤務評価と勤務年数によって時給が上がる評価制度をとっています。",
        },
        {
          id: 2,
          th: "業務内容",
          td: "HTML・CSSによるホームページの制作業務など",
        },
      ],
      tableList4: [
        {
          id: 1,
          th: "時間給",
          td: "1,050円～1,250円<br>※基本的に時給は1,050円で、入社時の保有スキルと実務経験によって優遇する場合があります。",
        },
        {
          id: 2,
          th: "業務内容",
          td: "HTML・CSSによるホームページの制作業務など",
        },
      ],
      tableList5: [
        {
          id: 1,
          th: "時間給",
          td: "1,250円～1,650円<br>※「長期間、勤務していただきたい」という思いから、スタートの時給は1,250円で、勤務評価と勤務年数によって時給が上がる評価制度をとっています。",
        },
        {
          id: 2,
          th: "業務内容",
          td: "Illustrator・Photoshop・HTML・CSSによるホームページのデザインとコーディング業務など",
        },
      ],
      tableList6: [
        {
          id: 1,
          th: "時間給",
          td: "1,100円～1,500円<br>※「長期間、勤務していただきたい」という思いから、スタートの時給は1,100円で、勤務評価と勤務年数によって時給が上がる評価制度をとっています。",
        },
        {
          id: 2,
          th: "業務内容",
          td: "Illustrator・Photoshopによるホームページやチラシなどのデザイン業務など",
        },
      ],
      tableList7: [
        {
          id: 1,
          th: "時間給",
          td: "1,100円～1,500円<br>※「長期間、勤務していただきたい」という思いから、スタートの時給は1,100円で、勤務評価と勤務年数によって時給が上がる評価制度をとっています。",
        },
        {
          id: 2,
          th: "業務内容",
          td: "マーケティングに関するサポート業務全般（調査・マーケティング、媒体の更新業務、文章の作成・修正、チェック業務、データ管理、など）、一般事務、雑務",
        },
      ],
      tableList8: [
        {
          id: 1,
          th: "時間給",
          td: "1,100円～1,500円<br>※「長期間、勤務していただきたい」という思いから、スタートの時給は1,100円で、勤務評価と勤務年数によって時給が上がる評価制度をとっています。",
        },
        {
          id: 2,
          th: "業務内容",
          td: "マーケティングに関するサポート業務全般（Google/Yahoo!/Facebook広告などの運用業務、調査・マーケティング、媒体の更新業務、文章の作成・修正、チェック業務、データ管理、など）、一般事務、雑務",
        },
      ],
      tableList9: [
        {
          id: 1,
          th: "時間給",
          td: "1,050円～1,250円<br>※「長期間、勤務していただきたい」という思いから、スタートの時給は1,050円で、勤務評価と勤務年数によって時給が上がる評価制度をとっています。",
        },
        {
          id: 2,
          th: "業務内容",
          td: "経理業務全般、一般事務、専門職のサポート業務全般（調査、媒体の更新業務、チェック業務、データ管理、など）、雑務",
        },
      ],
      tableList10: [
        {
          id: 1,
          th: "時間給",
          td: "1,050円～1,250円<br>※「長期間、勤務していただきたい」という思いから、スタートの時給は1,050円で、勤務評価と勤務年数によって時給が上がる評価制度をとっています。",
        },
        {
          id: 2,
          th: "業務内容",
          td: "一般事務、専門職のサポート業務全般（調査、媒体の更新業務、チェック業務、データ管理、など）、雑務",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.position-cover {
  margin: 50px 0 80px;
  p {
    color: var(--main);
    font-size: 25px;
    margin-bottom: 20px;
  }
  .detail-button {
    border: var(--sub) 1px solid;
    width: 200px;
    text-align: center;
    margin: 30px auto 0;
    padding: 10px 0;
    @media screen and (max-width: 767px) {
      padding: 15px 0;
    }
    a {
      display: block;
      text-decoration: none;
      color: var(--sub);
      transition: 0.8s;
      &:hover {
        color: white !important;
        background-color: var(--sub);
      }
    }
  }
  .detail{
    font-size: 18px;
    color: var(--black);
    margin-top: 20px;
  }
}

</style>
