<template>
  <div class="home-one-day">
    <div class="left">
      <div class="left-top">
        <img
          src="@/assets/GH-kyujin-top-illustration005.webp"
          alt="ペイント柄"
        />
        <div class="title">
          <h2 style="line-height:1.2;">A day at GreatHelp</h2>
          <p class="blue">1日の働き方のイメージ</p>
        </div>
      </div>
      <div class="img-cover-left">
        <img
          src="@/assets/GH-kyujin-top-illustration006.webp"
          alt="通勤イメージ"
        />
      </div>
      <div class="img-cover-right">
        <img
          src="@/assets/GH-kyujin-top-illustration013.webp"
          alt="お仕事イメージ"
        />
      </div>
      <div class="img-cover-left">
        <img
          src="@/assets/GH-kyujin-top-illustration014.webp"
          alt="アフター5イメージ"
        />
      </div>
    </div>
    <div class="right">
      <!-- <img
          src="@/assets/GH-kyujin-top-bou001.webp"
          alt="ブルーライン"
          class="blue-line"
        /> -->
      <div class="schedule">
        <p class="time">9時55分：出社</p>
        <p class="text">
         平日9時30分～17時で出勤時間は自由です。事前に申請した出勤時間に出社します。現在は、多くの人が10時出社をしています。遅刻OKなので、出社時間に間に合わせるために余裕をもってご自宅を早く出る必要はありません。社内のメンバーと雑談をしながら、コーヒーやお茶など、仕事中に飲みたいものを用意します。
        </p>
      </div>
      <div class="schedule">
        <p class="time">10時00分：始業</p>
        <p class="text">
          事前に上司から仕事の指示があります。メール、チャット、タスク管理アプリを確認して業務を開始します。朝礼などはありません。必要に応じて、上司・メンバーに声をかける、上司・メンバーから声がかかります。
        </p>
      </div>
      <div class="schedule">
        <p class="time">12時00分：昼食</p>
        <p class="text">
          昼食の休憩は自由です。昼食をとらず業務を続けてもOKです。おにぎりやパンなどの軽食であれば、タイムカードを休憩にすることなく、食べながらお仕事することもOKです（現在は、多くの方がこのスタイルです）。タイムカードを休憩にして、しっかりご飯を摂ってもOK。昼食の休憩も自由で、無駄な拘束時間をカットできます。
        </p>
      </div>
      <div class="schedule">
        <p class="time">13時00分：昼休憩</p>
        <p class="text">
          13時～13時10分は社内全体での昼休憩です。この昼休憩は、勤務時間として計上されない休憩時間です。
        </p>
      </div>
      <div class="schedule">
        <p class="time">15時00分：退勤</p>
        <p class="text">
          14時を過ぎると帰宅する人がポツポツとあらわれます。事前に申請した退勤時間になったら「お先に失礼します」とあいさつをして退勤。業務の進捗に応じて、勤務時間を延ばすことも可能です。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HomeOneDay",
  components: {},
  props: {
    path: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.home-one-day {
  margin-right: calc(calc(100vw - 1150px) * 0.5);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (min-width: 768px) and (max-width: 1150px) {
    margin-right: 0;
    padding-right: 20px;
    padding-left: 20px;
  }
  @media screen and (max-width: 767px) {
    display: block;
    margin-right: 0;
  }
}
.left {
  width: 45%;
  padding-left: 5%;
  @media screen and (min-width: 768px) and (max-width: 1150px) {
    padding-left: 0;
    width: 30%;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  .left-top {
    display: flex;
    align-items: flex-start;
    @media screen and (min-width: 768px) and (max-width: 1150px) {
      display: block;
    }
    @media screen and (max-width: 767px) {
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }
    .title {
      margin-left: 40px;
      @media screen and (max-width: 1150px) {
        margin-left: 0;
      }
      h2 {
        margin-bottom: 30px;
        @media screen and (min-width: 768px) and (max-width: 1150px) {
          font-size: 44px;
        }
        @media screen and (max-width: 767px) {
          font-size: 32px;
        }
      }
      p {
        color: var(--main);
      }
    }
    img {
      width: 150px;
      @media screen and (max-width: 767px) {
        transform: scale(-1, 1);
      }
    }
  }
  .img-cover-right,
  .img-cover-left {
    img {
      width: 250px;
      @media screen and (min-width: 768px) and (max-width: 1150px) {
        width: 60%;
      }
    }
  }
  .img-cover-right {
    text-align: right;
    @media screen and (max-width: 767px) {
      margin-top: 30px;
    }
  }
  .img-cover-left {
    text-align: left;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}
.right {
  width: 50%;
  position: relative;
  @media screen and (min-width: 768px) and (max-width: 1150px) {
    width: 67%;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 0 20px;
    margin-top: 50px;
  }

  .schedule {
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
    border-left: 5px solid var(--sub);
    // -webkit-appearance: none;
    @media screen and (min-width: 768px) and (max-width: 1150px) {
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    .time {
      width: 35%;
      min-width: 280px;
      color: var(--main);
      font-size: 25px;
      margin-top: -10px;
      @media screen and (min-width: 768px) and (max-width: 1150px) {
        font-size: 20px;
        margin-top: -11px;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      &:before {
        content: "●";
        color: var(--sub);
        margin-right: 40px;
        margin-left: -15px;
        @media screen and (min-width: 768px) and (max-width: 1150px) {
          margin-right: 10px;
          margin-left: -13px;
        }
        @media screen and (max-width: 767px) {
          margin-right: 10px;
        }
      }
    }
    &:last-child {
      border: none;
      padding-left: 5px;
    }
    .text {
      width: 60%;
      line-height: 1.5em;
      margin-top: -10px;
      @media screen and (min-width: 768px) and (max-width: 1150px) {
        width: 64%;
        margin-top: -11px;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-top: 20px;
        margin-left: 10px;
      }
    }
  }
}
</style>
