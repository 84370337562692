<template>
  <div class="home-work-environment wrap">
    <UiHeading :h="'4'" :type="'E2'" :text="'職場環境に関すること'" />

    <div class="box-cover">
      <UiBox1
        v-for="box in boxes"
        :key="box.id"
        :imgPath="box.imgPath"
        :subTitle="box.subTitle"
        :text="box.text"
        class="box"
      />
      <!-- <div class="corona">
        <h5>コロナ対策について</h5>
        <p class="mb">
          株式会社グレイトヘルプは、以下の理由から、コロナ感染リスクの低い職場です。
        </p>
        <ul class="mb">
          <li>全従業員が自動車通勤</li>
          <li>不特定多数が事務所内に出入りしない</li>
          <li>パート勤務者はクライアントとの打ち合わせなし</li>
        </ul>
        <p>
          その上で、デスク間に飛沫ガードを設置し、定期的な室内の換気をし、従業員と来客者にマスクの着用をお願いしています。<br />従業員やご家族様がコロナ感染およびそのリスクがある場合は、出勤を停止するなどの対策を行っています。
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiHeading from "../Ui/Heading.vue";
import UiBox1 from "@/components/Ui/Box1.vue";
export default {
  name: "HomeRecommendation",
  components: { UiHeading, UiBox1 },
  props: {
    path: String,
  },
  data() {
    return {
      boxes: [
        {
          id: 1,
          imgPath: "GH-kyujin-top-009.webp",
          subTitle: "ドリンクが充実",
          text: "コーヒー、紅茶、豆乳、青汁、お茶などドリンク類が充実。お好きなものを自由に飲むことができます。",
        },
        {
          id: 2,
          imgPath: "GH-kyujin-top-010.webp",
          subTitle: "軽食の支給",
          text: "カロリーメイト、チョコレート、スムージーなどの軽食を支給しています。お腹が空いたときに食べることができます。",
        },
        {
          id: 3,
          imgPath: "GH-kyujin-top-011.webp",
          subTitle: "<span class=row-3>冷蔵庫、電子レンジ、<br>ガラスケトルを完備</span>",
          text: "冷蔵庫、電子レンジ、ガラスケトルをご自由にお使いいただけます。",
        },
        {
          id: 4,
          imgPath: "GH-kyujin-top-012.webp",
          subTitle: "常備薬を完備",
          text: "頭痛薬、風邪薬などのお薬も自由に使用することができます。",
        },
        {
          id: 5,
          imgPath: "GH-kyujin-top-013.webp",
          subTitle: "服装、髪型、<br class=tb-only>ネイル自由",
          text: "服装、髪型、ネイル、全て自由です。ご自身の気持ちがもっとも高まるスタイルで仕事ができます。",
        },
        {
          id: 6,
          imgPath: "GH-kyujin-top-014.webp",
          subTitle: "ペーパータオルで<br>衛生面も◎",
          text: "トイレの洗面台やキッチンは、備え置きのタオルではなく、使い捨てのペーパータオルを使用しています。",
        },
        {
          id: 7,
          imgPath: "GH-kyujin-top-015.webp",
          subTitle: "トイレは男女別",
          text: "トイレは男女別です。音漏れ防止のために、トイレのドアは二枚になっています。",
        },
        {
          id: 8,
          imgPath: "GH-kyujin-top-016.webp",
          subTitle: "冬場は社内全体を<br class=tb-only>強力加湿",
          text: "冬場は、お肌の乾燥対策、菌やウィルスの飛散対策として、社内全体を強力加湿しています。",
        },
        // {
        //   id: 9,
        //   imgPath: "GH-kyujin-top-017.webp",
        //   subTitle: "社内完全禁煙、<br class=tb-only>喫煙者0",
        //   text: "社内に喫煙者はいません。来客者も禁煙となっているため、社内に喫煙場所がありません。",
        // },
        // {
        //   id: 10,
        //   imgPath: "GH-kyujin-top-018.webp",
        //   subTitle: "コロナ対策実施中",
        //   text: "従業員へのマスクと消毒液の支給、デスク間に飛沫ガードを設置、定期的な室内の換気をしています。",
        // },
      ],
    };
  },
  computed: {
    ...mapGetters(["rootUrl"]),
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.home-work-environment {
  // position: fixed;
  .box-cover {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
      display: block;
    }
    .box {
      width: 23%;
      margin-bottom: 50px;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 100px;
      }
    }
    .corona {
      width: 46%;
      @media screen and (max-width: 767px) {
        width: 100%;
      }
      h5 {
        background-color: var(--sub);
        color: white;
        font-size: 22px;
        padding: 10px;
          margin-bottom: 20px;
      }
      .mb {
        margin-bottom: 30px;
        line-height: 1.5em;
      }
      ul {
        li {
          list-style: disc;
          margin-left: 20px;
        }
      }
      p,
      ul {
        padding: 0 40px;
        @media screen and (max-width: 767px) {
          padding: 0 20px;
        }
      }
    }
  }
}
</style>
